import React, { Component } from 'react'
import { GenericContainer } from '../Generic/styles'
import { Appear } from '../../appear'
import { Text } from '../../text'
import { extractFields, deepExtractFields } from '../../../utils'
import { Testimony, TestimoniesContainer, TestimonyContainer } from './styles'
import { LazyImage, SimpleLazyImage } from '../../lazyImage'
import { Divider } from '../../divider'

export default ({
    heading, items
}) => {
    const headerContent = extractFields(heading)
    return (
        <GenericContainer>
                <Appear>
                    <Text>{headerContent.text}</Text>
                </Appear>
                <TestimoniesContainer>
                    {items && items.length !== 0 && items.map((item, index) => {
                        const content = deepExtractFields(item)
                        return (
                            <TestimonyContainer>
                                <Appear delay={(index % 3) * 250}>
                                    <Testimony key={`testimony_${index}`}>
                                        { content.image && content.image.length !== 0 && <SimpleLazyImage image={content.image} /> }
                                        {(!content.image || content.image.length === 0) && <img alt="no image for testimony" src="//images.ctfassets.net/4gvnjkct48dk/4l1F1yHP3dZPhw1uHbiGVd/91f9c84d3b9e489cb4eeb4370526b0be/NoImage.svg" /> }
                                        <Divider />
                                        <Text>
                                            {content.text.text}
                                        </Text>
                                    </Testimony>
                                </Appear>
                            </TestimonyContainer>
                        )
                    })}
                </TestimoniesContainer>
            </GenericContainer>
    )
}