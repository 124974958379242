import React, { Component, Fragment } from 'react';
import { 
    NavContainer, 
    Logo, 
    NavContent, 
    LogoLink, 
    NavAnchor, 
    ChildrenLinkContainer, 
    Children, 
    ChildrenLink, 
    NavBurger, 
    Container,
    BackButton,
    NavPlaceholder
} from './styles';
import {Link} from 'react-router-dom'

import logo from '../../assets/icon75.png';
import { withGlobalResources } from '../staticResources';
import { withCart } from '../cart';
import icons from './icons'


class ChildrenContainer extends Component {
    constructor(props) {
        super(props)
        this.toggleRegister = this.toggleRegister.bind(this)
        this.onClickOutside = this.onClickOutside.bind(this)
    }

    onClickOutside($event) {
        if (!this.node.contains($event.target)) {
            this.props.close()
        }
    }

    toggleRegister() {
        const {open} = this.props
        if (open) {
            document.addEventListener('click', this.onClickOutside)
        } else {
            document.removeEventListener('click', this.onClickOutside)
        }
    }


    render() {
        const {children, open, ...props} = this.props
        this.toggleRegister()
        return (
            <ChildrenLinkContainer ref={node => this.node = node} {...props} open={open}>
                {children}
            </ChildrenLinkContainer>
        )
    }
}

class Navigation extends Component {

    state = {
        showChildren: false,
        childrenTitle: '',
        children: [],
        open: false
    };

    constructor(props) {
        super(props);
        this.toggleChildren = this.toggleChildren.bind(this)
        this.menuBurgerClick = this.menuBurgerClick.bind(this)
        this.onClickNavItem = this.onClickNavItem.bind(this)
    }

    toggleChildren(item) {
        if(item) {
            this.setState({
                showChildren: true,
                childrenTitle: item.title,
                children: item.children
            })
        } 
        else 
        {
            this.setState({
                showChildren: false,
            })
        }
    }

    menuBurgerClick() {
        this.setState({ open: !this.state.open });
    }

    onClickNavItem(item) {
        if (item.url) {
            this.setState({open: false, showChildren: false})
        } else {
            this.toggleChildren(item)
        }
    }

    compileIcon(icon) {
        const createProp = (prop) => {
            if (prop.subject === 'props') {
                const p = this.props[prop.prop]
                return p[prop.method]()
            }
        }
        const Comp = icons[icon.key]
        const props = {}
        if (icon.props) {
            const propsKeys = Object.keys(icon.props) 
            for ( let i = 0; i < propsKeys.length; i++ ) {
                props[propsKeys[i]] = createProp(icon.props[propsKeys[i]])
            }
        }
        return <Comp {...props} />
    }

    render() {
        const { navTree, cart } = this.props
        const { showChildren,  childrenTitle, children, open} = this.state;
        return (
            <Fragment>
                <Container open={open} total={navTree.length}>
                    <NavContainer open={open} total={navTree.length}>
                        <LogoLink to="/">
                            <Logo src={logo} />
                        </LogoLink>
                        <NavContent open={open}>
                            {navTree && navTree.map((item, index) => {
                                const Icon = item.icon ? this.compileIcon(item.icon) : null
                                return (
                                    <NavAnchor 
                                        key={`link${index}`} 
                                        index={index}
                                        open={open}
                                        total={navTree.length}
                                        isIcon={Icon}
                                        onClick={() => this.onClickNavItem(item)}>
                                        
                                        <Link to={item.url ? item.url : null}>
                                            {item.title}
                                            {Icon && Icon}
                                        </Link>
                                    </NavAnchor>
                                )
                            })}
                        </NavContent>
                        <NavBurger onClick={this.menuBurgerClick} open={open}>
                            <div></div>
                            <div></div>
                            <div></div>
                        </NavBurger>
                    </NavContainer>
                    <ChildrenContainer close={this.toggleChildren} open={showChildren}>
                        <BackButton orientation="left" onClick={()  => this.setState({showChildren: false})} />
                        <Children type="title" onClick={() => this.setState({showChildren: false})}>{childrenTitle}</Children>
                        {children && children.map((child, id) => {
                            return (
                                <Children onClick={() => this.onClickNavItem(child)}>
                                    <ChildrenLink key={id} to={child.url}>
                                        {child.title}
                                    </ChildrenLink>
                                </Children>
                            )
                        })}
                    </ChildrenContainer>
                </Container>
                <NavPlaceholder />
            </Fragment>
        );
    }
}

const NavigationWithTree = withCart(withGlobalResources(Navigation))

export const withNavigation = Comp => props => (
    <div>
        <NavigationWithTree />
        <Comp {...props} />
    </div>
);

export default NavigationWithTree