export default [
    "Complete mobility, no compromise.",
    "Better than a $2000 machine.",
    "Pinpoint precision.",
    // "Utilizes recycled plastic.",
    "Easy to use.",
    "Fits into your pocket or purse.",
    "Do your daily chores during therapy.",
    "Years of high quality development.",
    "Frequently updated app.",
    "All programs complete free."
]