import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import { large, medium } from '../../utils'

export const StyledMarkdown = styled(ReactMarkdown)`
    .multi-button-container{
        width: 35%;
        margin: auto;
        display: flex;
    }
    ${
        large(`
            .multi-button-container{
                width: 60%;
            }
        `)
    }
    ${
        medium(`
            .multi-button-container{
                width: 80%;
                flex-direction: column;
            }
        `)
    }
`