/**
 * 
 * @param {string} name - the access token to the cookie
 */
export const getCookie = name => {
    let value
    try {
        value = document.cookie.split('; ').find(row => row.startsWith(name)).split('=')[1]
    } catch {
        return null
    }
    
    const [val, exp] = value.split(';')
    try {
        return {
            val: JSON.parse(val),
            exp: new Date(exp)
        }
    } catch (e) {
        console.warn(e)
        return {
            val, 
            exp: new Date(exp)
        }
    }
} 

// value can be simple string or object
// expiration can be a number setting it as number of days or as a date object
/**
 * 
 * @param {string} name - the cookie access token
 * @param {object|string} value - a string value or object value
 * @param {number|Date} expiration - the expiration date of the cookie. Either date object or number of days ahead  
 */
export const setCookie = (name, value, expiration) => {
    let exp
    if (!(expiration instanceof Date)) {
        exp = new Date();
        exp.setTime(exp.getTime() + (expiration*24*60*60*1000))
    } else {
        exp = expiration
    }

    let val = value
    if (value instanceof Object) {
        val = JSON.stringify(value)
    }
    var expires = "expires="+ exp.toUTCString();
    document.cookie = name + "=" + val + ";" + expires;
}