import styled from 'styled-components'

export const LoadingContainer = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transition: width 600ms, height 600ms, margin 600ms;
    border-radius: 50%;
    background: #FBFBFB;
    overflow: hidden;
    z-index: 1000;
    @media (orientation: landscape) {
        width: ${({loading}) => loading ? '180vw' : '0'};
        height: ${({loading}) => loading ? '180vw' : '0'};
        margin-top: ${({loading}) => loading ? '-90vw' : '1px'};
        margin-left: ${({loading}) => loading ? '-90vw' : '1px'};
    }
    @media (orientation: portrait) {
        width: ${({loading}) => loading ? '180vh' : '0'};
        height: ${({loading}) => loading ? '180vh' : '0'};
        margin-top: ${({loading}) => loading ? '-90vh' : '1px'};
        margin-left: ${({loading}) => loading ? '-90vh' : '1px'};
    }
`

export const LoadingText = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    color: #666;
    width: 100vw;
    padding: 15px;
    text-align: center;
    transition: opacity 500ms;
    transform: translate(-50%, -50%);
    opacity: ${({loading}) => loading ? '1' : '0'};
    font-size: 28px;
`