import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const FooterContainer = styled.div`
    width: 100%;
    background: #554654;
    padding: 2vh 5vw;
    color: #FFFBFF;
    box-sizing: border-box;
    border-top: 18px solid #1d181d;    
`

export const FooterSectionContainer = styled.div`
    display: flex;
    justify-content: center;
    ${({margin}) => margin ? `margin: ${margin};` : null}
`

export const FooterSection = styled.div`
    
    flex: 0 1 auto;
    padding: 0 2vw;
    text-align: center;
    h5{
        font-size: 18px;
        font-weight: bold;
        margin: 0.5vh 0;
    }
    ul{
        list-style: none;
        padding: 0.5vh 0;
        margin: 0;
        li{
            font-size: 16px;
            font-weight: 400;
            padding: 4px 0;
        }
    }
`

export const Copyright = styled.div`
    font-size: 12px;
    color: #1d181d;
`

export const MediaUrl = styled.a`
    margin: 0 4px;
`

export const FooterUrl = styled.a`
    color: #FFFBFF;
    text-decoration: none;
`

export const FooterLink = styled(Link)`
    color: #FFFBFF;
    text-decoration: none;
`