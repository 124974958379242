import React, { Component } from 'react'
import { GenericContainer } from '../Generic/styles'
import { FaqItem, Arrow, ArrowContainer } from './styles';
import { Text } from '../../text';
import { extractFields, deepExtractFields } from '../../../utils';
import { Appear } from '../../appear';

export default class FaqSection extends Component {


    constructor(props) {
        super(props)
        this.state = {
            selectedIndex: -1
        }
        this.setSelected = this.setSelected.bind(this)
    }

    setSelected(index) {
        if(this.state.selectedIndex === index) {
            this.setState({selectedIndex: -1})
        } else {
            this.setState({selectedIndex: index})
        }
    }

    render() {
        const { heading, items } = this.props
        const headerContent = extractFields(heading)
        const { selectedIndex } = this.state
        return (
        <GenericContainer>
            <Appear>
                <Text>{headerContent.text}</Text>
            </Appear>
                {items && items.length !== 0 && items.map((item, index) => {
                    const content = deepExtractFields(item)
                    const isOpen = selectedIndex === index;
                    return (
                        <Appear>
                            <FaqItem onClick={() => this.setSelected(index)} open={isOpen}>
                                <ArrowContainer><Arrow orientation={isOpen ? 'down' : 'right'} /></ArrowContainer>
                                <Text key={`faq_${index}`}>{content.text.text}</Text>
                            </FaqItem>
                        </Appear>
                    )
                })}
        </GenericContainer>)
    }
} 