import styled from 'styled-components'

export const Container = styled.div`
    position: relative;
`

export const StyledSelect = styled.select`
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #EEF;
    border-radius: 5px;
    font-size: 16px;
    margin: 20px 0 5px 0;
`

