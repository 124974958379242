import styled from 'styled-components';

export const StyledImage = styled.img`
    width: ${({width}) => width ? width : '100'}%;
`
export const LoadImage = styled.img`
    width: 1px;
    height: 1px;
    position: fixed;
    top: 1px;
    left: 1px;
`