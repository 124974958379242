import styled, { css } from 'styled-components'

export const Label = styled.label`
    position: absolute;
    left: 10px;
    font-size: 15px;
    font-weight: bold;
    top: calc(50% - 3px);
    color: #B0BEC5;
    transition: all 0.25s ease-in-out;
    cursor: text;
    ${({hasValue, focused}) => hasValue || focused ? css`
        transform: translateY(-160%);
        color: #333;

    ` : null}
    
    .required {
        color: red;
    }
`