import React from 'react'
import styled from 'styled-components'


export const Checkmark = ({height = '25px', width = '25px', color = '#000000'}) => (
    <IconSvg version="1.1" x="0px" y="0px" width={width} height={height} viewBox="0 0 448.8 448.8">
        <g>
            <g id="check">
                <polygon fill={color} points="142.8,323.85 35.7,216.75 0,252.45 142.8,395.25 448.8,89.25 413.1,53.55"/>
            </g>
        </g>
    </IconSvg>
)

export const ErrorIcon = ({height = '25px', width = '25px', color = '#000000'}) => (
    <IconSvg version="1.1" x="0px" y="0px" width={width} height={height} viewBox="0 0 286.054 286.054">
        <g>
            <path fill={color} d="M168.352,142.924l25.28-25.28c3.495-3.504,3.495-9.154,0-12.64l-12.64-12.649
                c-3.495-3.486-9.145-3.495-12.64,0l-25.289,25.289l-25.271-25.271c-3.504-3.504-9.163-3.504-12.658-0.018l-12.64,12.649
                c-3.495,3.486-3.486,9.154,0.018,12.649l25.271,25.271L92.556,168.15c-3.495,3.495-3.495,9.145,0,12.64l12.64,12.649
                c3.495,3.486,9.145,3.495,12.64,0l25.226-25.226l25.405,25.414c3.504,3.504,9.163,3.504,12.658,0.009l12.64-12.64
                c3.495-3.495,3.486-9.154-0.009-12.658L168.352,142.924z M143.027,0.004C64.031,0.004,0,64.036,0,143.022
                c0,78.996,64.031,143.027,143.027,143.027s143.027-64.031,143.027-143.027C286.054,64.045,222.022,0.004,143.027,0.004z
                M143.027,259.232c-64.183,0-116.209-52.026-116.209-116.209s52.026-116.21,116.209-116.21s116.209,52.026,116.209,116.209
                S207.21,259.232,143.027,259.232z"/>
        </g>
    </IconSvg>
)

export const InfoIcon = ({height = '25px', width = '25px', color = '#000000'}) => (
    <IconSvg version="1.1" x="0px" y="0px" width={width} height={height} viewBox="0 0 111.577 111.577">
        <g>
            <path fill={color} d="M78.962,99.536l-1.559,6.373c-4.677,1.846-8.413,3.251-11.195,4.217c-2.785,0.969-6.021,1.451-9.708,1.451
                c-5.662,0-10.066-1.387-13.207-4.142c-3.141-2.766-4.712-6.271-4.712-10.523c0-1.646,0.114-3.339,0.351-5.064
                c0.239-1.727,0.619-3.672,1.139-5.846l5.845-20.688c0.52-1.981,0.962-3.858,1.316-5.633c0.359-1.764,0.532-3.387,0.532-4.848
                c0-2.642-0.547-4.49-1.636-5.529c-1.089-1.036-3.167-1.562-6.252-1.562c-1.511,0-3.064,0.242-4.647,0.71
                c-1.59,0.47-2.949,0.924-4.09,1.346l1.563-6.378c3.829-1.559,7.489-2.894,10.99-4.002c3.501-1.111,6.809-1.667,9.938-1.667
                c5.623,0,9.962,1.359,13.009,4.077c3.047,2.72,4.57,6.246,4.57,10.591c0,0.899-0.1,2.483-0.315,4.747
                c-0.21,2.269-0.601,4.348-1.171,6.239l-5.82,20.605c-0.477,1.655-0.906,3.547-1.279,5.676c-0.385,2.115-0.569,3.731-0.569,4.815
                c0,2.736,0.61,4.604,1.833,5.597c1.232,0.993,3.354,1.487,6.368,1.487c1.415,0,3.025-0.251,4.814-0.744
                C76.854,100.348,78.155,99.915,78.962,99.536z M80.438,13.03c0,3.59-1.353,6.656-4.072,9.177c-2.712,2.53-5.98,3.796-9.803,3.796
                c-3.835,0-7.111-1.266-9.854-3.796c-2.738-2.522-4.11-5.587-4.11-9.177c0-3.583,1.372-6.654,4.11-9.207
                C59.447,1.274,62.729,0,66.563,0c3.822,0,7.091,1.277,9.803,3.823C79.087,6.376,80.438,9.448,80.438,13.03z"/>
        </g>
    </IconSvg>
)

export const WarningIcon = ({height = '25px', width = '25px', color = '#000000'}) => (
    <IconSvg version="1.1" x="0px" y="0px" width={width} height={height} viewBox="0 0 401.999 401.998">
        <g>
            <g>
                <path fill={color} d="M237.543,301.499h-73.091c-4.95,0-9.233,1.811-12.851,5.425c-3.615,3.617-5.424,7.897-5.424,12.847v63.953
                    c0,4.948,1.809,9.232,5.424,12.854c3.621,3.61,7.9,5.421,12.851,5.421h73.097c4.942,0,9.227-1.811,12.848-5.421
                    c3.61-3.621,5.42-7.905,5.42-12.854v-63.953c0-4.949-1.813-9.229-5.427-12.847C246.773,303.307,242.488,301.499,237.543,301.499z"
                    />
                <path fill={color} d="M259.383,5.424C255.862,1.812,251.628,0,246.676,0h-91.359c-4.948,0-9.18,1.812-12.703,5.424
                    c-3.521,3.617-5.186,7.902-4.996,12.85l7.992,219.265c0.19,4.948,2.139,9.236,5.852,12.847c3.711,3.621,8.041,5.431,12.991,5.431
                    h73.097c4.942,0,9.271-1.81,12.991-5.431c3.71-3.61,5.653-7.898,5.852-12.847l7.987-219.265
                    C264.578,13.326,262.905,9.045,259.383,5.424z"/>
            </g>
        </g>
    </IconSvg>
)


export const Close = ({height = 25, width = 25, color = '#000000', onClick=()=>{}}) => (
    <CloseIcon onClick={onClick} version="1.1" x="0px" y="0px" height={height} width={width} viewBox="0 0 47.971 47.971">
        <g>
            <path fill={color} d="M28.228,23.986L47.092,5.122c1.172-1.171,1.172-3.071,0-4.242c-1.172-1.172-3.07-1.172-4.242,0L23.986,19.744L5.121,0.88
                c-1.172-1.172-3.07-1.172-4.242,0c-1.172,1.171-1.172,3.071,0,4.242l18.865,18.864L0.879,42.85c-1.172,1.171-1.172,3.071,0,4.242
                C1.465,47.677,2.233,47.97,3,47.97s1.535-0.293,2.121-0.879l18.865-18.864L42.85,47.091c0.586,0.586,1.354,0.879,2.121,0.879
                s1.535-0.293,2.121-0.879c1.172-1.171,1.172-3.071,0-4.242L28.228,23.986z"/>
        </g>
    </CloseIcon>
)

export const CloseIcon = styled.svg`
    cursor: pointer;
    top: calc(50% - ${({height}) => height / 2}px);
    position: absolute;
    margin: auto;
    right: calc(50% - ${({width}) => width / 2}px);
`

export const IconSvg = styled.svg`
    margin: -4px;
`