import React, { Component } from 'react'
import { Container, Image } from './styles'
import { getImageSrc, deepExtractFields } from '../../../utils'
import { Appear } from '../../appear'
import { Text } from '../../text'
import { SimpleLazyImage } from '../../lazyImage'

export default class Media extends Component {
    render() {
        const { items, backgroundImage, backgroundColor, customCss, heading } = this.props
        return (
            <Container image={getImageSrc(backgroundImage)} color={backgroundColor}>
                {heading && (
                    <Appear>
                        <Text>{heading.fields.text}</Text>
                    </Appear>
                )}
                {
                    items && items.map(item => {
                        const content = deepExtractFields(item)
                        return (<SimpleLazyImage image={content.image} />)
                    })
                }
            </Container>
        )
    }
}