import styled, { keyframes, css } from 'styled-components';
import { large, small } from '../../utils';
import { ChevronButton } from '../../utils/common-styles';

const superSizeAnimation = keyframes`
    0% { transform: scale(1); }
    50% { transform: scale(1.3); }
    100% { transform: scale(1); }
`

export const Container = styled.div`
    cursor: ${({open}) => open ? 'default' : 'pointer'};
    transition: transform 0.5s, height 0.5s, width 0.5s, bottom 0.5s, right 0.5s, background 0.5s;
    transform: ${({hasItems}) =>  hasItems ? 'translate(0)' : `translateX(calc(3vw + 125%))`};
    position: fixed;
    bottom: 3vw;
    right: 3vw;
    z-index: 1001;
    @media (orientation: portrait) { 
        bottom: 3vh;
        right: 3vh;
    }
    
    width: calc(50px + 1vw);
    height: calc(50px + 1vw);

    svg#cart-icon {
        transition: ${({open}) => open ? 'opacity 0.2s, z-index 0s' : 'opacity 0.3s ease 0.3s, z-index 0s ease 0.6s'};
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        -webkit-box-shadow: 0px 3px 8px -1px rgba(85, 70, 84, 0.4);
        -moz-box-shadow: 0px 3px 8px -1px rgba(85, 70, 84, 0.4);
        box-shadow: 0px 3px 8px -1px rgba(85, 70, 84, 0.4);
        opacity: 1;
    }

    ${({open}) => open ? `
        bottom: 0 !important;
        right: 0 !important;
        background-color: #fff;
        height: 100vh;
        width: 35vw;
        -webkit-box-shadow: 0px 3px 8px -1px rgba(85, 70, 84, 0.4);
        -moz-box-shadow: 0px 3px 8px -1px rgba(85, 70, 84, 0.4);
        box-shadow: 0px 3px 8px -1px rgba(85, 70, 84, 0.4);
        svg#cart-icon {
            opacity: 0;
            z-index: -1;
        }
        @media (orientation: portrait) { 
            width: 50%;
        }
    ` : null}
    ${({open}) => open ? large(`
        width: 100%;
        bottom: 0;
        right: 0;
    `) : null}
`

export const Indicator = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    height: 35%;
    width: 35%;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid #eef;
    transition: ${({open}) => open ? `opacity 0.2s` : `opacity 0.2s ease 0.4s`};
    opacity: 1;
    ${({animate}) => animate ? css`
        animation: ${superSizeAnimation} 0.5s 0s forwards;
        animation-iteration-count: 1;
    ` : null}
    ${({open}) => open ? `
        opacity: 0;
    ` : null}
`
export const Centered = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
`
export const Background = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(85, 70, 84, 0.35);
    z-index: -1;
    opacity: 0;
    backdrop-filter: blur(6px);
    transition: opacity 0.5s, ${({open}) => open ? 'z-index 10ms' : 'z-index 500ms'};
    ${({open}) => open ? `
        z-index: 1000;
        opacity: 1;
    ` : null}
`

export const Details = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1002;
    transition: ${({open}) => open ? 'opacity 0.5s ease 0.35s' : 'opacity 0.2s'};
    opacity: ${({open}) => open ? 1 : 0};
    height: 100vh;
`

export const CartScrollable = styled.div`
    overflow: auto;
    height: calc(95vh - 115px);
    ${
        large(`
            height: calc(100vh - 128px);
        `)   
    }
`

export const CartRow = styled.div`
    ${
        ({divider}) => divider ? 'border-bottom: 1px solid #EEF;' : null
    }
    padding: ${({padding}) => padding ? padding : '2vw'};
    
    display: flex;
    ${
        large(`
            padding: 0;
        `)
    }
`

export const LineItemImage = styled.img`
    flex: auto;
    width: 10%;
    ${
        large(`
            width: 15%;
        `)
    }
`

export const LineItemDetails = styled.div`
    flex: auto;
    width: 47.5%;
    padding: 0 7px;
    ${
        large(`
            width: 47.5%;
            padding: 5px 2px;
        `)
    }
`

export const CartColumn = styled.div`
    flex: auto;
    width: ${({width}) => width ? width : 'auto'};
    text-align: center;
    font-weight: ${({fontWeight}) => fontWeight ? fontWeight : '100'};
    color: ${({color}) => color ? color : 'inherit'};
    ${
        large(`
            font-size: 13px;
            padding: 5px 2px;
        `)
    }
`

export const LineItemTitle = styled.div`
    font-weight: bold;
    font-size: 17px;
    ${
        large(`
            font-size: 14px;
        `)
    }
`

export const LineItemRemove = styled.button`
    border-radius: 50%;
    border: 3px solid #EEF;
    background-color: #EEF;
    font-size: 13px;
    font-weight: bold;
    padding: 0;
    box-sizing: border-box;
    width: 28px;
    height: 28px;
    cursor: pointer;
`

export const CartHeader = styled.div`
    position: relative;
    padding: 15px 2vw;
    display: flex;
    border-bottom: 1px solid #EEF;
    ${ ({padding}) => 
        large(`
            padding: ${padding ? padding : '25px 0'};
        `)
    }
`

export const CartHeaderColumn = styled.div`
    position: relative;
    flex: auto;
    width: ${({width}) => width ? width : 'auto'};
    text-align: ${({textAlign}) => textAlign ? textAlign : 'center'};
    font-weight: bold;
    font-size: 18px;
    ${
        large(`
            font-size: 15px;
            text-align: center;
        `)
    }
`

export const CloseDetailsButton = styled.button``

export const StepButton = styled.button`
    transition: background-color 0.5s;
    position: absolute;
    bottom: 0;
    min-height: 5vh;
    background-color: #554654;
    color: #FFF;
    width: 100%;
    font-size: 17px;
    border: none;
    font-weight: bold;
    cursor: pointer;
    &:hover{
        background-color: #715d70;
    }
    ${
        large(`
            height: 45px;
        `)
    }
`

export const DeleteButton = styled.button``

export const BackButton = styled(ChevronButton)`
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    display: none;
    ${({open}) => open ? large(`display: block;`) : null};
`

export const QuantityFieldContainer = styled.div`
    display: flex;
    border: 1px solid #EEF;
    border-radius: 3px;
    height: 30px;
    ${
        small(`
            flex-direction: column-reverse;
            height: 100%;
        `)
    }
`

export const QuantityFieldNumber = styled.div`
    flex: auto;
    position: relative;
    background-color: #EEF;
    div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`

export const QuantityFieldButton = styled.button`
    flex: auto;
    padding: 0;
    border: none;
    background-color: #EEF;
    cursor: pointer;
`

export const StepIndicator = styled.div`
    position: relative;
    height: 0;
    width: 100%;
    padding-bottom: 100%;
    border-radius: 50%;
    background-color: ${({active}) => active ? '#11407a':'#EEF'};
    color: ${({active}) => active ? '#FFF':'inherit'}
    transition: color 0.5s, background 0.5s;
    cursor: pointer;
    font-size: 1.7em;
    font-weight: bold;
    div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`

export const StepBetween = styled.div`
    width: 100%;
    height: 3px;
    background-color: ${({active}) => active ? '#11407a':'#EEF'};
    color: ${({active}) => active ? '#FFF':'inherit'}
    transition: color 0.5s, background 0.5s;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
`

export const ShippingOptionHeader = styled.div`
    margin: 0 0 5px 0;
    font-size: 16px;
    font-weight: bold;
`

export const PaymentOption = styled.div`
    display: flex;
`

export const PaymentLabelContainer = styled.div`
    flex: 1;
    font-weight: bold;
    vertical-align: middle;
    position: relative;
`

export const PaymentLabel = styled.div`
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 22px;
`

export const PaymentIcon = styled.img`
    flex: 0;
    max-height: 70px;
`