import styled, { css, keyframes } from 'styled-components'

export const Spinner = styled.div`
    width: ${({size}) => size ? size : '40px'};
    height: ${({size}) => size ? size : '40px'};

    position: relative;
    margin: 100px auto;
`

const BounceAnimation = keyframes`
    0%, 100% { 
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    } 50% { 
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
`

const BounceCommon = css`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #11407a;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: ${BounceAnimation} 2.0s infinite ease-in-out;
    animation: ${BounceAnimation} 2.0s infinite ease-in-out;
`

export const BounceOne = styled.div`
    ${BounceCommon}
`

export const BounceTwo = styled.div`
    ${BounceCommon}
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
`