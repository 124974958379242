import styled, { css } from 'styled-components';
import { GenericContainer } from '../Generic/styles';
import { small, large, medium } from '../../../utils';

export const Container = styled(GenericContainer)`

    ${({image}) => {
            if(image) {
                return css`
                    padding: 0;
                    &:before{
                        content: " ";
                        width: 0;
                        margin-left: -1px;
                        float: left;
                        height: calc(100vh - 61px);
                        background: rgba(85, 70, 84, 0.25);
                    }

                    &:after{
                        content: " ";
                        display: table;
                        clear: both;
                    }
                `
            } else {
                return css`
                    padding: 0;
                `
            }
        }
    }
`

export const SwiperContainer = styled.div`
    ${({makeAbsolute}) => makeAbsolute ?
    css`
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    ` : 
    css`
        position: relative;
    `}
    width: 100%;
`

export const SwipeItem = styled.div`
    display: flex;
    justify-content: center;
    ${
        medium(`
            width: 90%;
            margin: auto;
            justify-content: center;
            flex-direction: column;
        `)
    }
    div{
        flex: 1 1 auto;
    }
    ${({customCss}) => customCss ? customCss.map(value => {
        return `${value.fields.key}: ${value.fields.value};`
    }) : null}
`

export const ImageContainer = styled.div`
    flex-basis: 45% !important;
`

export const TextContainer = styled.div`
    
    ${({left}) => left ? css`
        text-align: left;
        a{
            margin: unset !important;
        }
        ${
            medium(`
            a{
                margin: auto !important;
            }
            `)
        }
        
    ` : null}
    ${({coloredTitle}) => coloredTitle ? css`
        text-align: center;
        h1{
            background-color: rgb(85, 70, 84);
            color: white;
        }
        h2, h3, h4, h5, h6{
            padding: 3% 7%;
        }
    ` : null}
    flex-basis: auto;
    h1{
        font-size: 4em;
    }

    h2 {
        font-size: 3em;
    }

    h3 {
        font-size: 2.25em;
    }

    h4 {
        font-size: 1.75em;
    }
    h5 {
        font-size: 1.25em;
    }
    ${
        ({color}) => color ? `color: ${color.color};` : null
    }
    ${
        large(`
            h1{
                font-size: 2.75em;
            }
        
            h2 {
                font-size: 2.25em;
            }
        
            h3 {
                font-size: 1.75em;
            }
        
            h4 {
                font-size: 1.25em;
            }
            h5 {
                font-size: 1em;
            }
        `)
    }
    ${
        medium(`
            text-align: center;
            h1{
                font-size: 2.5em;
            }
        
            h2 {
                font-size: 2em;
            }
        
            h3 {
                font-size: 1.5em;
            }
        
            h4 {
                font-size: 1em;
            }
            h5 {
                font-size: 0.75em;
            }
            h6 {
                font-size: 0.5em;
            }
        `)
    }
`

export const ArrowButton = styled.button`
    ${({orientation}) => orientation == 'left' ? null : null}

`