import React, { Component, Fragment, Children } from 'react'
import { StyledImage, LoadImage } from './styles'
import LazyLoading from './LazyLoad'
import Axios from 'axios'

export default class LazyImage extends Component{

    constructor(props) {
        super(props)
        this.action = this.action.bind(this)
        this.state = {
            placeholder: this.extractImageSrc(0)
        }
    }

    componentDidMount(){
        this.setState({placeholder: this.extractImageSrc(0)})
    }    

    action() {
        return new Promise((resolve) => {
            const { image } = this.props
            if (image.length > 1) {
                const finalPath = this.extractImageSrc(1)
                const onLoaded = () => {
                    this.setState({LoadComp: undefined})
                    resolve(finalPath)
                }
                this.setState({LoadComp: () => (
                    <LoadImage src={finalPath} onLoad={onLoaded} />
                )})
            } else {
                resolve(this.extractImageSrc(0))
            }
        })
    } 

    extractImageSrc(index) {
        const { image } = this.props
        const i = image[index]
        if (!i) {
            throw new Error(`Could not fetch image with index ${index}`, image)
        }
        if (!i.fields) {
            return i.file.url
        }
        return i.fields.file.url
    }

    render() {
        const { placeholder, LoadComp } = this.state

        const { children } = this.props
        return (
            <Fragment>
                <LazyLoading action={this.action} placeholder={placeholder}>
                    {src => src && children(src)}
                </LazyLoading>
                {LoadComp && <LoadComp />}
            </Fragment>
        )
    }
}

export const SimpleLazyImage = ({image, width}) => 
{
    return (
        <LazyImage image={image}>
            {src => <StyledImage width={width} src={src} />}
        </LazyImage>
    )
}
