import styled, { css } from 'styled-components'
import { Label } from '../styles'

export const InputContainer = styled.div`
    position: relative;
`

export const DefaultInput = styled.input`
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #EEF;
    border-radius: 5px;
    font-size: 16px;
    margin: 20px 0 5px 0;
    transition: box-shadow 0.25s ease-in-out;
    &:focus {
        box-shadow: 0 3px 9px rgba(0,0,0,0.3);
        outline: none;
    }

    &::placeholder {
        color: #B0BEC5;
    }
`

export const TelInput = styled.input`
    width: calc(100% - 40px);
    padding: 0;
    box-sizing: border-box;
    border: none;
    font-size: 16px;
    outline: none;

    &::placeholder {
        color: #B0BEC5;
    }
`

export const InputArea = styled.div`
    background-color: #fff;
    width: 100%;
    height: 38px;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #EEF;
    border-radius: 5px;
    margin: 20px 0 5px 0;
    transition: box-shadow 0.25s ease-in-out;
    cursor: text;
    ${({focused}) => focused ? css`
        box-shadow: 0 3px 9px rgba(0,0,0,0.3);
    ` : null}
`

export const FlagContainer = styled.div`
    position: absolute;
    left: 10px;
    height: 16px;
    top: 11px;

`

export const PhoneLabel = styled(Label)`
    left: 30px;
    top: 11px;
`