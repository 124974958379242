import React, { Component, Fragment, createRef } from 'react'
import { ReactComponent as CartIcon } from '../../assets/cart.svg'
import { 
    Container, 
    Indicator, 
    Centered, 
    Background, 
    Details, 
    CartRow, 
    StepButton, 
    LineItemImage, 
    LineItemDetails, 
    LineItemTitle, 
    CartHeader, 
    CartHeaderColumn, 
    CartColumn, 
    BackButton, 
    QuantityFieldButton, 
    QuantityFieldContainer, 
    QuantityFieldNumber, 
    StepBetween, 
    StepIndicator, 
    CartScrollable, 
    ShippingOptionHeader, 
    PaymentOption,
    PaymentIcon,
    PaymentLabel,
    PaymentLabelContainer,
    LineItemRemove
} from './styles'
import { withCart } from './CartProvider'
import { Form, Input, Select, RadioButtons, Checkbox } from '../form'
import countries from './countries'
import axios from 'axios'
import { AsYouType } from 'libphonenumber-js'
import { withNotificationManager } from '../notifications'
import { Loader } from '../loader'
import { ModalLink } from '../modal/ModalProvider'


const QuantityField = ({setQuantity, quantity}) => {
    const mutateQuantity = (mutation) => {
        if(mutation < 0 && quantity === 1) {
            return
        }
        const newValue = quantity + mutation
        setQuantity(newValue)
    }

    return (
        <QuantityFieldContainer>
            <QuantityFieldButton disabled={quantity === 1} onClick={() => mutateQuantity(-1)}>-</QuantityFieldButton>
            <QuantityFieldNumber><div>{quantity}</div></QuantityFieldNumber>
            <QuantityFieldButton onClick={() => mutateQuantity(1)}>+</QuantityFieldButton>
        </QuantityFieldContainer>
    )
}


class Cart extends Component {

    formRef = null

    constructor(props) {
        super(props)
        this.state = {
            animate: false,
            open: false,
            step: 0,
            countryCode: 'AF',
            shippingMethods: [],
            paymentMethods: [],
            fetching: {
                shippingMethods: true,
                paymentMethods: false
            },
            FormComp: null
        }
        this.onAnimationTriggered = this.onAnimationTriggered.bind(this)
        props.cart.attachAnimationTrigger(this.onAnimationTriggered)
        this.onAnimationEnd = this.onAnimationEnd.bind(this)
        this.setCartOpen= this.setCartOpen.bind(this)
        this.setQuantity = this.setQuantity.bind(this)
        this.removeLineItem = this.removeLineItem.bind(this)
        this.setStep = this.setStep.bind(this)
        this.onCountrySelected = this.onCountrySelected.bind(this)
        this.onShippingSelected = this.onShippingSelected.bind(this)
        this.getPaymentMethods = this.getPaymentMethods.bind(this)
        this.performCheckout = this.performCheckout.bind(this)
        this.getPaymentMethods()
        
        setTimeout(() => this.getShippingMethods(), 0) 
    }

    onAnimationTriggered() {
        this.setState({animate: true})
    }

    onAnimationEnd() {
        this.setState({animate: false})
    }

    setCartOpen(open, event) {
        this.setState({open})
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

    }

    setQuantity(id, qty) {
        const { cart } = this.props
        cart.setQuantity(id, qty)
    }

    removeLineItem(id, $event) {
        const { cart } = this.props
        const itemsAfter = cart.removeItem(id)
        if (itemsAfter === 0) {
            this.setCartOpen(false, $event)
        }
    }

    setStep(step) {
        if (step === 2) {
            const { checkout } = this.props
            const validationResult = checkout.validateShipping()
            if(!validationResult){
                this.setState({step})
            } else if (validationResult.input) {
                this.props.notificationManager.add('warning', 'Shipping Info Incomplete', 'Please finish filling in the shipping info before selecting payment method.', true, 10000)
                
            } else {
                this.props.notificationManager.add('warning', 'Shipping Info Incomplete', 'Shipping method needs to be set before selecting payment.', true, 10000)
            }
        } else {
            this.setState({step})
        }
    }

    phoneNumberHandling($event, formState) {
        const { value } = $event.target
        const { countryCode } = formState
        
        const phone = new AsYouType(countryCode)
        phone.input(value)
        try {
            return phone.getNumber().format('NATIONAL')
        } catch(ex) {
            return ''
        }
    }

    onCountrySelected($event, formState, setField) {
        const { value } = $event.target
        this.setState({countryCode: value})
        this.getShippingMethods(value)
        setField('shippingMethod', undefined)
        setField('price', undefined)
        setField('vendor', undefined)
        return value
    }

    onShippingSelected($event, formState, setField) {
        
        const { price, vendor } = this.state.shippingMethods.filter(method => method.id === $event.target.value)[0]
        setField('price', price)
        setField('vendor', vendor)
        return $event.target.value
    }

    getShippingMethods(countryCode) {
        if(!countryCode) {
            try {
                countryCode = this.props.checkout.get().shipping.countryCode
                if (!countryCode) {
                    countryCode = 'AF'
                }
            } catch (e) {
                console.warn('could not find initial country code')
                countryCode = 'AF'
            }
        }

        if (this.shippingMethodRequest) {
            this.shippingMethodRequest.cancel()
        }
        
        this.shippingMethodRequest = axios.CancelToken.source()
        const { fetching } = this.state
        fetching.shippingMethods = true
        this.setState({shippingMethods: [], fetching })
        axios({
            method: 'get',
            url: `/api/shipping/?countryCode=${countryCode}`,
            headers: {
                'Content-Type' : 'application/json'
            },
            cancelToken: this.shippingMethodRequest.token
        })
        .then(r => {
            const { fetching } = this.state
            fetching.shippingMethods = false
            this.setState({shippingMethods: r.data.data, fetching})
        })
        .catch(e => {
            if(!axios.isCancel(e)) {
                this.props.notificationManager.add('error', 'Error', e.toString(), true, 10000)
                const { fetching } = this.state
                fetching.shippingMethods = false
                this.setState({fetching})
            } else {
                console.warn(`fetching shipping for ${countryCode} has been cancelled`)
            }
        })
        .finally(() => {
            this.shippingMethodRequest = null
        })
    }

    getShippingById(id) {
        if(!this.state.shippingMethods || this.state.shippingMethods.length === 0) {
            return 
        }
    }

    getPaymentMethods() {
        const { fetching } = this.state
        fetching.paymentMethods = true
        this.setState({fetching})
        axios({
            method: 'get',
            url: '/api/payments/',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(r => {
            this.setState({paymentMethods: r.data})
        })
        .catch(e => {
            console.error('Could not fetch payment methods')
            console.error(e)
        })
        .finally(() => {
            const { fetching } = this.state
            fetching.paymentMethods = false
            this.setState({fetching})
        })
    }

    performCheckout() {
        const { checkout } = this.props
        this.formRef = createRef()
        checkout.complete().then(r => {
            const fields = r.data.data
            this.setState({FormComp: () => (
                <form method="POST" action={r.data.action} ref={f => this.formRef = f} >
                    {fields && Object.keys(fields).length && Object.keys(fields).map(fieldKey => (
                        <input type="hidden" name={fieldKey} value={fields[fieldKey]} />
                    ))}
                </form>
            )})
            setTimeout(() => {
                this.formRef.submit()
            }, 0)
        }).catch(e => console.error(e))
    }

    render() {
        const { cart, checkout } = this.props
        const { animate, open, step, shippingMethods, fetching, paymentMethods, FormComp } = this.state
        const lineItems = Object.values(cart.get())
        const lineItemsKeys = Object.keys(cart.get())
        return (
            <Fragment>
                <Container onClick={() => this.setCartOpen(true)} open={open} hasItems={cart.getCount() !== 0}>
                    <CartIcon />
                    <Indicator open={open} onAnimationEnd={this.onAnimationEnd} animate={animate}>
                        <Centered >{cart.getCount()}</Centered>
                    </Indicator>
                    <Details open={open}>
                        <CartHeader padding="25px 15vw">
                            <BackButton open={open} orientation="left" onClick={(event) => this.setCartOpen(false, event)} />
                            <CartHeaderColumn width="5%" onClick={() => {if (open) this.setStep(0)}}><StepIndicator active="true"><div>1</div></StepIndicator></CartHeaderColumn>
                            <CartHeaderColumn width="27.5%"><StepBetween active={step >= 1}></StepBetween></CartHeaderColumn>
                            <CartHeaderColumn width="5%" onClick={() => {if (open) this.setStep(1)}}><StepIndicator active={step >= 1}><div>2</div></StepIndicator></CartHeaderColumn>
                            <CartHeaderColumn width="27.5%"><StepBetween active={step === 2}></StepBetween></CartHeaderColumn>
                            <CartHeaderColumn width="5%" onClick={() => {if (open) this.setStep(2)}}><StepIndicator active={step === 2}><div>3</div></StepIndicator></CartHeaderColumn>
                        </CartHeader>
                        {step === 0 && (<CartScrollable>
                            <CartHeader>
                                <CartHeaderColumn width="55%" textAlign="left">Product Details</CartHeaderColumn>
                                <CartHeaderColumn width="12.5%">Qty</CartHeaderColumn>
                                <CartHeaderColumn width="12.5%">Price</CartHeaderColumn>
                                <CartHeaderColumn width="12.5%">Total</CartHeaderColumn>
                                <CartHeaderColumn width="7.5%"></CartHeaderColumn>
                            </CartHeader>
                            {lineItems && lineItems.length !== 0 && lineItems.map((item, index) => {
                                return (
                                    <CartRow key={`lineItem_${index}`} divider="true">
                                        <LineItemImage src={`${item.images[0].file.url}?w=100`} />
                                        <LineItemDetails>
                                            <LineItemTitle>{item.title}</LineItemTitle>
                                        </LineItemDetails>
                                        <CartColumn width="12.5%">
                                            <QuantityField quantity={item.qty} setQuantity={(qty) => this.setQuantity(lineItemsKeys[index], qty)} />
                                        </CartColumn>
                                        <CartColumn width="12.5%" fontWeight="bold" color="#715d70">
                                            ${item.price}
                                        </CartColumn>
                                        <CartColumn width="12.5%" fontWeight="bold" color="#715d70">
                                            ${item.price * item.qty}
                                        </CartColumn>
                                        <CartColumn width="7.5%" fontWeight="bold" color="#715d70">
                                            <LineItemRemove onClick={$event => this.removeLineItem(item.id, $event)}>x</LineItemRemove>
                                        </CartColumn>
                                    </CartRow>
                                )
                            })}
                            <CartHeader>
                                <CartHeaderColumn width="87.5%" textAlign="right">Subtotal</CartHeaderColumn>
                                <CartHeaderColumn width="12.5%">${cart.getTotal()}</CartHeaderColumn>
                            </CartHeader>
                            <StepButton onClick={() => this.setStep(1)}>Select Shipping</StepButton>
                        </CartScrollable>)}
                        {
                            step === 1 && (
                                <CartScrollable>
                                    <CartHeader>
                                        <CartHeaderColumn width="100%">Shipping Address</CartHeaderColumn>
                                    </CartHeader>
                                    <Form 
                                        onChange={checkout.setShipping}
                                        customHandlers={{
                                            phone: this.phoneNumberHandling,
                                            countryCode: this.onCountrySelected,
                                            shippingMethod: this.onShippingSelected
                                        }}
                                        onSubmit={() => {
                                            this.setStep(2)
                                        }}
                                        initFields={checkout.get().shipping}>
                                        <CartRow padding="7px 2vw">
                                            <CartColumn width="100%">
                                                <Input name="firstName" placeholder="First Name" required />
                                            </CartColumn>
                                        </CartRow>
                                        <CartRow padding="7px 2vw">
                                            <CartColumn width="100%">
                                                <Input name="lastName" placeholder="Last Name" required />
                                            </CartColumn>
                                        </CartRow>
                                        <CartRow padding="7px 2vw">
                                            <CartColumn width="100%">
                                                <Input name="email" placeholder="Email" required />
                                            </CartColumn>
                                        </CartRow>
                                        <CartRow padding="7px 2vw">
                                            <CartColumn width="100%">
                                                <Input name="address1" placeholder="Address Line 1" required />
                                            </CartColumn>
                                        </CartRow>
                                        <CartRow padding="7px 2vw">
                                            <CartColumn width="100%">
                                                <Input name="address2" placeholder="Address Line 2" />
                                            </CartColumn>
                                        </CartRow>
                                        <CartRow padding="7px 2vw">
                                            <CartColumn width="100%">
                                                <Input name="city" placeholder="City" required />
                                            </CartColumn>
                                        </CartRow>
                                        <CartRow padding="7px 2vw">
                                            <CartColumn width="100%">
                                                <Input name="state" placeholder="State" />
                                            </CartColumn>
                                        </CartRow>
                                        <CartRow padding="7px 2vw">
                                            <CartColumn width="100%">
                                                <Input name="postalCode" placeholder="Postal Code" required />
                                            </CartColumn>
                                        </CartRow>
                                        <CartRow padding="7px 2vw">
                                            <CartColumn width="100%">
                                                <Select name="countryCode" options={countries} label="name" value="code" placeholder="Country" required />
                                            </CartColumn>
                                        </CartRow>
                                        <CartRow padding="7px 2vw">
                                            <CartColumn width="100%">
                                                <Input type="tel" name="phone" placeholder="Phone Number" />
                                            </CartColumn>
                                        </CartRow>
                                        <CartRow padding="7px 2vw">
                                            <CartColumn width="100%">
                                                {
                                                    shippingMethods && shippingMethods.length !== 0 && (
                                                        <RadioButtons 
                                                            name="shippingMethod"
                                                            placeholder="Shipping Method"
                                                            required
                                                            options={shippingMethods}
                                                            valueKey="id">
                                                            {option => (
                                                                <Fragment>
                                                                    <ShippingOptionHeader>{option.name + ' - $' + option.price}</ShippingOptionHeader>
                                                                    <div>{option.estimatedDelivery}</div>
                                                                    <div>{option.description}</div>
                                                                </Fragment>
                                                            )}
                                                        </RadioButtons>
                                                    )
                                                }
                                                {
                                                    (!shippingMethods || shippingMethods.length === 0) && !fetching.shippingMethods && (<div>No shipping methods available</div>)
                                                }
                                                {
                                                    fetching.shippingMethods && (<Loader size="80px" />)
                                                }
                                            </CartColumn>
                                        </CartRow>
                                        <StepButton type="submit">Select Payment</StepButton>
                                    </Form>
                                    
                                   
                                    
                                </CartScrollable>
                            )
                        }
                        {
                            step === 2 && (
                                <CartScrollable>
                                    <CartHeader>
                                        <CartHeaderColumn width="100%">Review Order and Shipping</CartHeaderColumn>
                                    </CartHeader>
                                    {lineItems && lineItems.length !== 0 && lineItems.map((item, index) => {
                                        return (
                                            <CartRow key={`lineItem_${index}`} divider="true">
                                                <CartColumn width="25%">
                                                    {item.title}
                                                </CartColumn>
                                                <CartColumn width="50%">
                                                    ${item.price} x {item.qty}
                                                </CartColumn>
                                                <CartColumn width="25%">
                                                    ${item.price * item.qty}
                                                </CartColumn>
                                            </CartRow>
                                        )
                                    })}
                                    <CartRow divider="true">
                                        <CartColumn width="25%">
                                            Shipping
                                        </CartColumn>
                                        <CartColumn width="50%" />
                                        <CartColumn width="25%">
                                            ${checkout.get().shipping.price}
                                        </CartColumn>
                                    </CartRow>
                                    <CartRow divider="true">
                                        <CartColumn fontWeight="bold" width="75%">
                                            Total
                                        </CartColumn>
                                        <CartColumn width="25%">
                                            ${checkout.getTotal()}
                                        </CartColumn>
                                    </CartRow>
                                    <Form 
                                        initFields={{ paymentMethod: checkout.get().paymentMethod}}
                                        onChange={checkout.setPaymentMethod}
                                        onSubmit={this.performCheckout}
                                        >
                                        <CartRow>
                                            <CartColumn>
                                                <RadioButtons name="paymentMethod" placeholder="Payment Method" options={paymentMethods} required>
                                                    {option => (
                                                        <PaymentOption>
                                                            <PaymentIcon src={option.iconUrl} />
                                                            <PaymentLabelContainer>
                                                                <PaymentLabel>
                                                                    {option.label}
                                                                </PaymentLabel>
                                                            </PaymentLabelContainer>
                                                        </PaymentOption>
                                                    )}
                                                </RadioButtons>
                                            </CartColumn>
                                        </CartRow>
                                        <Checkbox name="terms" required>
                                            I have read and agree to the <ModalLink to="/terms">terms and conditions</ModalLink>
                                        </Checkbox>
                                        <StepButton type="submit">Proceed to Pay</StepButton>
                                    
                                    </Form>
                                    {FormComp && <FormComp />}
                                </CartScrollable>
                            )
                        }
                    </Details>
                </Container>
                <Background open={open} onClick={() => this.setCartOpen(false)} />
            </Fragment>
            
        )
    }
}

export default withNotificationManager(withCart(Cart))