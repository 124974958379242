import React, { Component, useState, Fragment } from 'react'
import { withCart } from '../../cart'
import { ProductContainer, ImageContainer, ActionContainer, AddButton, Header, Description, ThumbnailContainer, Thumbnail, ActiveImage, CartIconContainer, CartTextContainer, CartAnimationContainer, ItemAdded } from './styles'
import { GenericContainer } from '../Generic/styles'
import { extractFields, deepExtractFields } from '../../../utils'
import { Text } from '../../text'
import { Appear } from '../../appear'
import { ReactComponent as CartIcon } from '../../../assets/cartNoBg.svg'
import { LoadImage } from '../../lazyImage'

const Gallery = ({images}) => {
    const [active, setActive] = useState(0)
    const [loaded, setLoaded] = useState({})

    const onClickThumbnail = (index) => {
        setActive(index)
    }

    return (
        <ImageContainer>
            <ThumbnailContainer>
                {images && images.map((image, index) => {
                    const onLoad = () => {
                        setLoaded({[index]: true, ...loaded})
                    }
                    return (<Fragment>
                            <Thumbnail active={index === active} onClick={() => onClickThumbnail(index)} key={index} src={image.file.url} />
                            {!loaded[index] && <LoadImage src={image.file.url} onLoad={onLoad} />}
                        </Fragment>)
                })}
            </ThumbnailContainer>
            <ActiveImage src={images[active].file.url} width={images[active].file.details.image.width} height={images[active].file.details.image.height} />
        </ImageContainer>
    )
} 

class ProductSection extends Component {

    constructor(props) {
        super(props)

        this.state = {
            qty: 1,
            activeAnimation: false
        }

        this.addToCart = this.addToCart.bind(this)
        this.alterQty = this.alterQty.bind(this)
        this.startAddToCartAnimation = this.startAddToCartAnimation.bind(this)
        this.onAddToCartAnimationEnd = this.onAddToCartAnimationEnd.bind(this)
    }

    alterQty(alter) {
        const altered = this.state.qty + alter
        if(altered > 0){
            this.setState({ qty:  altered})
        }
    }

    addToCart() {
        const { cart } = this.props
        const { qty } = this.state
        const item = deepExtractFields(this.props.items[0])
        cart.addItem(item, qty)
    }

    startAddToCartAnimation() {
        this.setState({ activeAnimation: true })
        setTimeout(() => this.addToCart(), 1220)
    }

    onAddToCartAnimationEnd() {
        this.setState({ activeAnimation: false })
    }


    render() {
        const { items } = this.props
        const product = items.length ? deepExtractFields(items[0]) : null
        const { qty, activeAnimation } = this.state
        return (
            <GenericContainer>
                {product && (
                    
                        <ProductContainer>
                            <Gallery images={product.images}></Gallery>
                            <ActionContainer>
                                <Appear>
                                    <Header>
                                        <h1>{product.title}</h1>
                                        <h2>{`$${product.price}`}</h2>
                                    </Header>
                                    <Description>
                                        <Text>{product.description}</Text>
                                    </Description>
                                    <AddButton>
                                        <button class="subtract" onClick={() => this.alterQty(-1)} disabled={qty === 1}>-</button>
                                        <input class="qty" value={qty} disabled="true" />
                                        <button class="add" onClick={() => this.alterQty(1)}>+</button>
                                        <button class="toCart" disabled={activeAnimation} onClick={this.startAddToCartAnimation}>
                                            <CartAnimationContainer activeAnimation={activeAnimation} onAnimationEnd={this.onAddToCartAnimationEnd}>
                                                <CartIconContainer><CartIcon /><ItemAdded activeAnimation={activeAnimation} /></CartIconContainer>
                                                <CartTextContainer><div>Add to Cart</div></CartTextContainer>
                                            </CartAnimationContainer>
                                        </button>
                                    </AddButton>
                                </Appear>
                            </ActionContainer>
                        </ProductContainer>
                )}
            </GenericContainer>
        )
    }
}


export default withCart(ProductSection)