import styled from 'styled-components'

export const Container = styled.div`
    position: fixed;
    top: 25px;
    right: 0px;
    padding-right: 15px;
    z-index: 10000;
    overflow: hidden;
`

export const Notification = styled.div`
    width: 450px;
    padding: 15px;
    background: #EEE;
    border-radius: 5px;
    border-left: 5px solid #EEE;
    positions: relative;
    box-shadow: 0 3px 9px rgba(0,0,0,0.3);
    display: flex;
    margin-bottom: 25px;
    transition: all 0.5s ease;
    transform: translateX(0);

    &.opening {
        transform: translateX(150%);
    }

    &.closing {
        transform: translateX(150%);
    }
`

export const IconContainer = styled.div`
    width: 15%;
    position: relative;
`

export const IconCircle = styled.div`
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    width: 14px;
    height: 14px;
    padding: 7px;
    border-radius: 50%;
    display: inline-block;
    box-shadow: 0 3px 3px rgba(0,0,0,0.3);
`

export const TextContainer = styled.div`
    text-align: left;
    flex: 1;
`
export const CloseContainer = styled.div`
    width: 15%;
    position: relative;
`

export const Header = styled.div`
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 6px;
`

export const Message = styled.div`
    color: #666;
`

export const Warning = styled(Notification)`
    border-color: #FFC007;
    ${IconCircle} {
        background-color: #FFC007;
    }
`

export const Info = styled(Notification)`
    border-color: #1D72F3;
    ${IconCircle} {
        background-color: #1D72F3;
    }
`

export const Error = styled(Notification)`
    border-color: #F93614;
    ${IconCircle} {
        background-color: #F93614;
    }
`

export const Success = styled(Notification)`
    border-color: #2BDE3F;
    ${IconCircle} {
        background-color: #2BDE3F;
    }
`