import styled from 'styled-components';
import { GenericContainer } from '../Generic/styles';
import { small, large, xLarge } from '../../../utils';

export const Container = styled(GenericContainer)`
    text-align: center;
`

export const CardsContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    ${
        small(`
            flex-direction: column;
        `)
    }
`

export const CardContainer = styled.div`
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    padding: 20px;
    box-sizing: border-box;
    ${
        small(`
            width: 100%;
        `)
    }
`

export const Card = styled.div`
    width: 100%;
    background-color: ${ ({color}) => color ? color : 'transparent'};
    margin: 14px auto 14px auto;
    padding: 14px;
    text-align: center;
    box-sizing: border-box;
    ${({customCss}) => customCss ? customCss.map(value => {
        return `${value.fields.key}: ${value.fields.value};`
    }) : null}
    img {
        width: 40%;
    }

   
    
    ${
        xLarge(`img { width: 50%}`)
    }

    ${
        large(`img { width: 60% }`)
    }
`