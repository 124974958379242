import React from 'react'
import styled from 'styled-components'
import { small } from '../../utils'
import { Link } from 'react-router-dom'
import { ChevronButton, Small } from '../../utils/common-styles'

export const Container = styled.div`
    position: fixed;
    transition: height 500ms ease ${({open, total}) => open ? 0 : (total) * 150}ms;
    height: ${({open}) => open ? `100%` : `75px`};
    width: 100%;
    z-index: 999;
`

export const NavContainer = styled.div`
    width: 100%;
    transition: all 500ms ease ${({open, total}) => open ? 0 : (total) * 150}ms;
    height: ${({open}) => open ? '100%;' : '75px;'}
    position: absolute;
    z-index: 9;
    &:after {
        content: " ";
        display: block;
        background-color: #fff;
        height: 60px;
        width: 100%;
        transition: all 500ms ease ${({open, total}) => open ? 0 : (total) * 150}ms;
        box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.25);
        position: absolute;
        top: 0;
        z-index: -1;
        border-bottom: 1px solid rgba(15, 15, 15, 0.25);

        ${({open}) => small(`
            height: ${open ? '100%;' : '60px;'}
        `)}
    }
`

export const NavPlaceholder = styled.div`
    position: relative;
    height: 60px;
    z-index: -1;
`

export const NavBurger = styled.div`
    display: none;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    width: 45px;
    height: 30px;

    div{
        position: absolute;
        height: 5px;
        width: 100%;
        background: #0a346a;
        transition: all 200ms ease-in-out;
        &:nth-child(1) {
            ${({open}) => open === true ? `
            top: 12.5px;
            transform: rotate(45deg)` : `top: 0;`}
        }
        &:nth-child(2) {
            top: 12.5px;
            ${({open}) => open ? 'opacity: 0;' : 'opacity: 1;'}
        }
        &:nth-child(3) {
            ${({open}) => open === true ? `
            bottom: 12.5px;
            transform: rotate(-45deg)
            ` : `bottom: 0;`}
        }
    }
    
    ${small(`
        display: block;
    `)}
`

export const NavContent = styled.div`
    width: 80%;
    margin: auto;
    display: flex;
    align-items: center;
    ${({open}) => small(`
        width: 100%;
        flex-direction: column;
        margin-top: 15px;
        visibility: ${open ? `visible` : `hidden`};
    `)}
`

export const NavAnchor = styled.div`    
    ${
        ({open, index, total}) => {
            const finalIndex = open ? index : total - 1 - index;
            const addition = open ? 500 : 0;
            return small(`
                transition: all 250ms ease ${addition + (150 * finalIndex)}ms;
                opacity: ${open ? `1;`: `0;`}
                transform: ${open ? `translateX(0)` : `translateX(110%)`};
            `)
        }
    }
    
    margin: 14px 25px 14px 0;
    ${small(`margin-right: 0;`)}
    ${({isIcon}) => isIcon ? 'margin-right: 60px !important' : null}

    transition: all 0.5s ease;
    position: relative;
    cursor: pointer;
    transition: color 0.5s ease;
    
    a{
        color: #666;
        font-size: 24px;
        text-decoration: none;
    }

    &:before {
        transition: all 0.5s ease;
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: #554654;
        transform: scaleX(0);
    }

    &:hover{
        a {
            color: #554654;
        }
        
        &:before {
            transform: scaleX(1);
        }

        svg {
            path {
                transition: stroke 0.5s;
                stroke: #554654 !important;
            }
            text, tspan {
                transition: fill 0.5s;
                fill: #554654 !important;
            }
        }
    }

    

    svg {
        height: 45px;
        width: 45px;
        position: absolute;
        height: 45px;
        top: -6px;
        right: -55px;
        
        path {
            stroke: #666 !important;
        }
        text, tspan {
            fill: #666 !important;
        }
        
    }
`

export const LogoLink = styled(Link)`
    position: absolute;
    left: 3px;
    top: 3px;
`

export const Logo = styled.img`
    width: 69px;
    height: 69px;
    margin: 3px 3px;
`

export const ChildrenLinkContainer = styled.div`
    background: rgba(15, 33, 80, 0.15);
    position: absolute;
    top: 60px;
    width: 100%;
    height: auto;
    padding: 2%;
    opacity: ${({open}) => open ? 1 : 0};
    transition: opacity 500ms ${({open}) => !open ? ', z-index 10ms linear 500ms' : null};
    box-sizing: border-box;
    padding-top: 15px;
    ${
        ({open}) => small(`
            z-index: ${open ? `10` : `-1`}
            opacity: ${open ? `1` : `0`}
            height: 100%;
            width: 100%;
            background: white;
            top: 0;
        `)
    }
`

export const Children = styled.div`
    text-align: left;
    font-size: 24px;
    text-decoration: ${({type}) => type === 'title' ? 'underline' : 'none'};
    color: #666666;
    margin: 14px 25px 14px 14px;
    ${({type}) => type === 'title' ? 'margin-left: 0; cursor: pointer;' : ''}
    ${
        small(`

            margin-left: 14px;
            text-align: center;
        `)
    }
`

export const BackButton = (props) => (
    <BackButtonContainer>
        <ChevronButton {...props}></ChevronButton>
    </BackButtonContainer>
)

const BackButtonContainer = styled(Small)`
    position: absolute;
    top: 24px;
    left: 24px;
`

export const ChildrenLink = styled(Link)`
    color: #666666;
    font-size: 24px;
    text-decoration: none !important;
    
`
