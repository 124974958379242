import React, { Fragment } from 'react';
import { StyledLink, StyledAppLink, AppLinkIcon, AppLinkText, AppLinkTextHeader, AppLinkTextMain } from './styles';
import { ReactComponent as AppleIcon } from '../../../assets/apple.svg'
import { ReactComponent as PlayIcon } from '../../../assets/google-play-store.svg'

export default ({title, ...props}) => {
    const Link = selectLink(title)
    return (
        <Link {...props} title={title} />
    )
}

export const DefaultLink = ({href, ...props}) => {
    return (
        <StyledLink href={href}>
            {props.children}
        </StyledLink>
    )
}

export const AppLink = ({title, href, ...props}) => {
    const [head, main] = props.children[0].props.value.split(';')
    return (
        <StyledAppLink href={href} target="_blank">
            <AppLinkIcon>
                {title && title === 'ios' && <AppleIcon />}
                {title && title === 'android' && <PlayIcon />}
            </AppLinkIcon>
            <AppLinkText>
                <AppLinkTextHeader>{head}</AppLinkTextHeader>
                <AppLinkTextMain>{main}</AppLinkTextMain>
            </AppLinkText>
        </StyledAppLink>
    )
}

const selectLink = (title) => {
    switch(title) {
        case 'android':
        case 'ios':
            return AppLink
    }
    return DefaultLink
}