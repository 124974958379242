import styled, { css } from 'styled-components';
import { GenericContainer } from '../Generic/styles';
import { small, xLarge, large, medium } from '../../../utils';
import { Text } from '../../text';

export const Container = styled(GenericContainer)`
    width: 100%;
    text-align: center;
`

export const InnerContainer = styled.div`
    display: flex;
    justify-content: left;
    width: 100%;
    margin: 1% 0;
    ${({imageAlign}) => 
        xLarge(`
            text-align: center;
            ${imageAlign === 'left' ? 'flex-direction: column' : 'flex-direction: column-reverse'};
            
        `)
    }
    ${({customCss}) => customCss ? customCss.map(value => {
        return `${value.fields.key}: ${value.fields.value};`
    }) : null}
`

export const ImageContainer = styled.div`
    flex: 0 0 ${({width}) => width};
    ${
        xLarge(`
            img{
                width: 50%;
            }
        `)
    }
    ${
        large(`
            img{
                width: 60%;
            }
        `)
    }
    ${
        medium(`
            img{
                width: 75%;
            }
        `)
    }
`

export const RelativeTextContainer = styled.div`
    position: relative;
    flex: 0 0 auto;
    text-align: center;
    ${({isImage, textWidth}) => !isImage ? 'width: 100%;' : css`
        width: ${textWidth};
        .multi-button-container{
            width: 65%;
        }
        ${
            xLarge(`
                .multi-button-container{
                    width: 65%;
                }
            `)
        }
        ${
            large(`
                .multi-button-container{
                    width: 80%;
                }
            `)
        }
        ${
            medium(`
                .multi-button-container{
                    width: 90%;
                }
            `)
        }
    `}
    
    ${
        xLarge(`
            width: 100%;
        `)
    }
`

export const AbsoluteTextContainer = styled.div`
    
    position: absolute;
    top: 50%;
    ${({isImage}) => 
        isImage ? `
            left: 50%;
            transform: translate(-50%, -50%);
            width: 90%;
        ` : `
            transform: translateY(-50%);
            width: 100%;
        `
    }

    ${
        xLarge(`
            position: relative;
            transform: none;
            left: auto;
            top: auto;
            width: 100%;
        `)
    }
`