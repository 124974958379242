import React, { Component, Fragment } from 'react'
import { Container, ModalFrame } from './styles'
import Axios from 'axios'
import { SectionResolver } from '../section'
import { Loader } from '../loader'

export default class Modal extends Component{

    constructor(props) {
        super(props)
        this.state = {
            to: '',
            sections: [],
            fetching: false
        }
    }

    componentDidUpdate() {
        if (this.props.to != this.state.to && this.props.to != '') {
            const { to } = this.props
            this.setState({to, fetching: true})
            Axios.get(`/api/page/?path=${encodeURI(to)}`).then(response => {
                this.setState({ sections: response.data.fields.sections })
            }).finally(() => this.setState({fetching: false}))
        }
    }

    render() {
        const { sections, fetching } = this.state
        const { open, triggerClose,  } = this.props
        return (
            <Fragment>
                {open && 
                    (<Container onClick={triggerClose}>
                        <ModalFrame>
                            {sections && sections.map((section, key) => (
                                <SectionResolver key={key} {...section.sys} {...section.fields}></SectionResolver> 
                            ))}
                            {fetching && <Loader size="80px" />}
                        </ModalFrame>
                    </Container>)}
            </Fragment>
        )
    }
}