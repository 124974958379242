export const extractFields = (obj) => {
    return Array.isArray(obj) ? obj.map(item => item.fields) : obj.fields;
}

export const deepExtractFields = (obj) => {
    let output
    if (obj === null) {
        return null
    }
    
    if (Array.isArray(obj)) {
        output = obj.map(item => deepExtractFields(item))
    }
    else if(obj.fields) {
        const temp = obj.fields
        const fields = Object.keys(temp)
        output = {}
        for (let i = 0; i < fields.length; i++) {
            const key = fields[i]
            output[key] = deepExtractFields(temp[key])
        }
    } else {
        output = obj
    }

    return output
}

export const getImageSrc = (image) => {
    return image && image.fields ? image.fields.file.url : undefined
}

export const hasImage = (image) => {
    if (!image) {
        return false
    }
    if (Array.isArray(image)) {
        return image.length > 0;
    } else {
        return image.fields && image.fields.file && image.fields.file.url
    }
}