import React, { Component, Fragment } from 'react'
import { Container, SwiperContainer, SwipeItem, ImageContainer, TextContainer } from './styles'
import { extractFields, deepExtractFields } from '../../../utils'
import { LazyImage, SimpleLazyImage } from '../../lazyImage'
import { Text } from '../../text'

const Swiper = ({items, withBackground, ...props}) => {
    return (
        <SwiperContainer makeAbsolute={withBackground}>
            {items && extractFields(items).map((fields, index) => {
                const { image, text, customCss } = fields              
                const content = deepExtractFields(text)
                const shouldDisplayImage = image && image.length > 0
                return (
                    <SwipeItem key={`swipe-item_${index}`} customCss={customCss}>
                        {shouldDisplayImage && (<ImageContainer>
                            <SimpleLazyImage image={image}/>
                        </ImageContainer>)}
                        <TextContainer color={content.color ? content.color : null} coloredTitle={!withBackground} left={shouldDisplayImage}>
                            <Text>{content.text}</Text>
                        </TextContainer>
                    </SwipeItem>
                )
            })}
        </SwiperContainer>
    )
}

export default class PageHead extends Component {
    constructor(props) {
        super(props)
    }

    render(){
        const { backgroundImage, bgImage, backgroundColor, items } = this.props
        return (
            <Fragment>
                {
                    bgImage && bgImage.length > 1 && (
                        
                        <LazyImage image={bgImage}>
                            {src => (
                                <Container image={src} color={backgroundColor} >
                                    <Swiper items={items} withBackground={backgroundImage !== null}></Swiper>
                                </Container>
                            )}
                        </LazyImage>
                    )
                }
                {
                    bgImage && bgImage.length === 1 && (
                        <Container image={bgImage[0].fields.file.url} color={backgroundColor} >
                            <Swiper items={items} withBackground={backgroundImage !== null}></Swiper>
                        </Container>
                    )
                }
                {
                    !bgImage || bgImage.length === 0 && (
                        <Container color={backgroundColor} >
                            <Swiper items={items} withBackground={backgroundImage !== null}></Swiper>
                        </Container>
                    )
                }
            </Fragment>
            
            
        );
    }
}