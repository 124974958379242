import React from 'react'
import { FooterContainer, FooterSection, Copyright, FooterSectionContainer, MediaUrl, FooterUrl, FooterLink } from './styles'
import { withGlobalResources } from '../staticResources'

export default withGlobalResources((props) => {
    const {footerTree} = props
    return (
        <FooterContainer>
            <FooterSectionContainer>
                {footerTree.sections && footerTree.sections.map((section, index) => {
                    return (<FooterSection key={`f_section_${index}`}>
                        <h5>{section.title}</h5>
                        <ul>
                            {section.items.map(
                                (item, iIndex )=>
                                {
                                    const link = localLink(item.url) ? (<FooterLink to={item.url}>{item.title}</FooterLink>) : (<FooterUrl href={item.url} target="_blank">{item.title}</FooterUrl>)
                                    return (<li key={`item_${index}_${iIndex}`}>{link}</li>)
                                }
                            )}
                        </ul>
                    </FooterSection>)
                })}
            </FooterSectionContainer>
            <FooterSectionContainer margin="20px 0 0 0">
                {footerTree.socialMedia && footerTree.socialMedia.map(media => <MediaUrl href={media.url} target="_blank">
                    <img width="48" height="48" src={media.imgUrl} />
                </MediaUrl>)}
            </FooterSectionContainer>
            <FooterSectionContainer margin="1vh 0">
                <Copyright>{footerTree.copyright}</Copyright>
            </FooterSectionContainer>
        </FooterContainer>
    )
})

const localLink = (url) => {
    return url.indexOf('/') === 0
}
