import React, { Fragment } from 'react'

export default (props) => {
    return (
    <Fragment>{getCorrectHeading(props)}</Fragment>
    )
}

const getCorrectHeading = (props) => {
    if(props.children && props.children.length > 0) {
        try {
            const checkValue = props.children[0].props.value.trim()
            const jsonValue = JSON.parse(checkValue)
            props.children.shift()
            props = {...props, ...jsonValue}
        } catch (e) {}
    }
    switch(props.level) {
        case 1:
            return <h1 {...props} />
        case 2:
            return <h2 {...props} />
        case 3:
            return <h3 {...props} />
        case 4:
            return <h4 {...props} />
        case 5:
            return <h5 {...props} />
        case 6:
            return <h6 {...props} />
        default:
            return <h6 {...props} />

    }
}