import styled from 'styled-components'
import { small } from '../../utils'

export const GdprDialogContainer = styled.div`
    position: fixed;
    bottom: 0px;
    right: 0px;
    left: 0px;
    min-height: 100px;
    background: white;
    display: flex;
    flex-wrap: wrap;
    padding: 18px;
    border-top: 10px solid #012559;
    justify-content: space-between;
    ${small(`
        flex-direction: column;
    `)}
`

export const GdprHeader = styled.h3`
    flex: 1 0 auto;
    width: 100%;
`

export const GdprTextContainer = styled.div`
    font-weight: bold;
`

export const GdprButtonContainer = styled.div`
    padding: 10px 15px;
    background: #012559;
    color: white;
    font-size: 18px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
`