import React from 'react'

export default ({itemCount}) => {
    const traceStyle = {
        fill: 'none',
        stroke: '#554654',
        strokeWidth: '2.64583333',
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        strokeOpacity: '1',
        strokeMiterlimit: '4',
        strokeDasharray: 'none'
    }
    return (
        <svg
            id="shoppingBag"
            version="1.1"
            viewBox="0 0 52.916665 52.916668"
            height="200"
            width="200">
    
            <g
                id="layer1">
                <path
                id="path833"
                d="M 25.418899,21.450149 H 6.8980655 L 3.4962798,45.829614 47.43601,46.207589 44.60119,29.765625 42.71131,21.261161 Z"
                style={traceStyle} />
                    <path
                    id="path835"
                    d="m 11.055803,21.355655 c 0,0 -2.1733625,-18.0483634 13.796131,-18.0483634 15.969493,0 13.985118,18.0483634 13.985118,18.0483634"
                    style={traceStyle} />
                    <text
                    id="text839"
                    y="43.030396"
                    x="18"
                    style={{
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        fontSize: '10.5833px',
                        lineHeight: '1.25',
                        fontFamily: 'sans-serif',
                        fill: '#554654',
                        fillOpacity: '1',
                        stroke: 'none',
                        strokeWidth: '0.264583'
                    }}
                    >
                    <tspan
                    style={{
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        fontWeight: 'bold',
                        fontStretch: 'normal',
                        fontSize: '25.4px',
                        fontFamily: 'sans-serif',
                        strokeWidth: '0.264583',
                        fill: '#554654',
                        fillOpacity: '1'
                    }}
                    y="43.030396"
                    x="18"
                    id="tspan837">{itemCount}</tspan></text>
            </g>
        </svg>
)}