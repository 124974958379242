import React, { Component } from 'react'
import { Container, DefaultInput, CheckCircle, RadioLabel } from './styles'
import { withFormController } from '../Form'
import { Label } from '../styles'
import { withNotificationManager } from '../../notifications'
import { requiredValidation } from '../utils'

let index = 0

const DefaultOption = ({option, children, ...props}) => {

    return (
        <DefaultInput {...props}>
            <CheckCircle {...props}/>
            {children ? children : option.label}
        </DefaultInput>)
}

class RadioButtons extends Component {
    constructor(props) {
        super(props)
        this.name = props.name || `Radio${index++}`
        this.validate = this.validate.bind(this)
    }

    validate() {
        if(!requiredValidation(this.props, this.name)) {
            const { notificationManager, placeholder } = this.props
            notificationManager.add('warning', `'${placeholder}' required`, `You need to provide a value before continuing.`, true, 10000)
            return false
        }
        return true
    }

    componentDidMount() {
        this.props.formController.addValidationListener(this.name, this.validate)
    }

    componentWillUnmount() {
        this.props.formController.removeValidationListener(this.name)
    }

    render() {
        const { formController: {handleChange, getValue}, radioInput, options, children, placeholder, required } = this.props
        const Comp = radioInput ? radioInput : DefaultOption
        const { name } = this
        const value = getValue(name)
        return (
            <Container>
                <RadioLabel htmlFor={name} hasValue={value !== ''} focused="true">{placeholder} {required && <span className="required">*</span>}</RadioLabel>
                {
                    options && 
                    options.map(
                        (option, index) => {
                            const optionValue = getOptionValue(option, this.props)
                            const selected = value === optionValue
                            return children ? 
                                (<Comp key={`radio_${name}_${index}`} 
                                    onClick={() => handleChange({target: {type: 'radio', name, value: optionValue}})} 
                                    selected={selected}>{children(option)}</Comp>) : 
                                (<Comp key={`radio_${name}_${index}`} 
                                    onClick={() => handleChange({target: {type: 'radio', name, value: optionValue}})} 
                                    selected={selected} 
                                    option={option} />)
                        }
                    )
                }
            </Container>
        )
    }
}

const getOptionValue = (option, props) => {
    const key = props.valueKey || 'value'
    return option[key]
}

export default withNotificationManager(withFormController(RadioButtons)) 