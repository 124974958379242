import React, { Component } from 'react'
import { Container, ImageContainer, InnerContainer, RelativeTextContainer, AbsoluteTextContainer } from './styles'
import { Text } from '../../text'
import { SimpleLazyImage } from '../../lazyImage'
import { Appear } from '../../appear'
import { getImageSrc, deepExtractFields } from '../../../utils'


export default class SimpleText extends Component {
    render() {
        const { items, backgroundImage, backgroundColor, customCss, heading } = this.props        
        const data = deepExtractFields(items[0])
        const isImage = data.image && data.image.length > 0
        const customData = data.customData && data.customData.length > 0 ? data.customData[0] : {}
        const imageAlign = customData.image && customData.image.align ? customData.image.align : 'left'
        const imageWidth = customData.image && customData.image.width ? customData.image.width : '35%'
        const textWidth = customData.text && customData.text.width ? customData.text.width : '65%'
        return (
            <Container image={getImageSrc(backgroundImage)} color={backgroundColor} >
                {heading && (
                    <Appear>
                        <Text>{heading.fields.text}</Text>
                    </Appear>
                )}
                {items && (
                    <Appear>
                        <InnerContainer imageAlign={imageAlign} customCss={customCss}>
                            {
                                isImage && imageAlign === 'left' && (
                                    <ImageContainer width={imageWidth}>
                                        <SimpleLazyImage image={items[0].fields.image} />
                                    </ImageContainer>
                                )
                            }
                            <RelativeTextContainer className={"prufa"} isImage={isImage} textWidth={textWidth}>
                                <AbsoluteTextContainer isImage={isImage}>
                                    <Text>{items[0].fields.text.fields.text}</Text>
                                </AbsoluteTextContainer>
                            </RelativeTextContainer>
                            {
                                isImage && imageAlign !== 'left' && (
                                    <ImageContainer width={imageWidth}>
                                        <SimpleLazyImage image={items[0].fields.image} />
                                    </ImageContainer>
                                )
                            }
                        </InnerContainer>
                    </Appear>
                )}
            </Container>
        )
    }
} 
