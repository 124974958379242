import styled from 'styled-components'

export const Container = styled.div`
    position: relative;
    margin: 2vw;
    padding: 2em 2em 2em 3.5em;
`

export const CheckboxContainer = styled.div`
    position: absolute;
    display: inline-block;
    cursor: pointer;
    margin: 0 3px 0 3px;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    label {
        display: block;
        height: ${({height}) => height};
    }
    
    .checkmark {
        opacity: 0;
    }

    input[type=checkbox]:checked + label .checkmark{
         opacity: 1;
    }
`

export const Input = styled.input`
    margin: 0;
    width: ${({width}) => width};
    height: ${({height}) => height};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    appearance: none;
    cursor: pointer;
`

export const Required = styled.span`
    color: red;
`