import { SimpleText } from '../SimpleText';
import React, { Component } from 'react';
import { withCart } from '../../cart/CartProvider';


class ThankYou extends Component{
    constructor(props) {
        super(props)
        props.cart.empty()
    }

    render() {
        const {children, ...props} = this.props
        return (
            <SimpleText {...props}>
                {children}
            </SimpleText>
        )
    }
}

export default withCart(ThankYou)