import { ScreenSizes } from './screens'
import { css } from 'styled-components'

const sized = (size) => (content) => sizedControlled(`max-width: ${size}px`)(`${content}`)

const sizedControlled = (mediaStatment) => (content) => css`
    @media (${mediaStatment}) {
        ${content}
    }
`

const small = (content) => sized(ScreenSizes.sm)(`${content}`)

const medium = (content) => sized(ScreenSizes.md)(`${content}`)

const large = (content) => sized(ScreenSizes.lg)(`${content}`)

const xLarge = (content) => sized(ScreenSizes.xlg)(`${content}`)

const xxLarge = (content) => sized(ScreenSizes.xxlg)(`${content}`)


const overXxLarge = (content) => sizedControlled(`min-width: ${ScreenSizes.lg}px`)(`${content}`)

export { small, medium, large, xLarge, xxLarge, overXxLarge }