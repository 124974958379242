import styled from 'styled-components';
import { small, medium } from '../../../utils';

export const StyledLink = styled.a`
    color: #FFF;
    display: block;
    border-radius: 5px;
    padding 10px;
    background-color: rgb(85, 70, 84, 0.75);
    transition: background 0.4s;
    width: max-content;
    margin: auto;
    width: max-content;
    margin: auto;
    text-decoration: none;
    text-shadow: none;
    &:hover{
        background-color: rgba(85, 70, 84, 0.9);
    }
    ${
        medium(`
            margin-bottom: 10px;
        `)
    }
`

export const StyledAppLink = styled.a`
    color: #FFF;
    display: flex;
    border-radius: 5px;
    padding 10px;
    background-color: rgb(0,0,0);
    transition: background 0.4s;
    width: max-content;
    margin: auto;
    width: max-content;
    margin: auto;
    text-decoration: none;
    text-shadow: none;
    ${
        medium(`
            margin-bottom: 10px;
        `)
    }
`

export const AppLinkIcon = styled.div`
    svg {
        height: 48px;
        width: 48px;
    }
`

export const AppLinkText = styled.div``

export const AppLinkTextHeader = styled.div`
    font-size: 14px;
`

export const AppLinkTextMain = styled.div``