import React, { createContext, Component } from 'react'

export const FormContext = createContext()

export default class Form extends Component {
    constructor(props) {
        super(props)
        this.validationListeners = {}
        this.initFields()
        this.setField = this.setField.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.getValue = this.getValue.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.triggerOnFormChange = this.triggerOnFormChange.bind(this)
        this.addValidationListener = this.addValidationListener.bind(this)
        this.removeValidationListener = this.removeValidationListener.bind(this)
        this.validate = this.validate.bind(this)
    }

    initFields() {
        const { initFields } = this.props
        if(initFields) {
            this.state = initFields
        }
    }

    setField(name, value) {
        this.setState({[name]: value})
        setTimeout(this.triggerOnFormChange, 0)
    }

    handleChange($event) {
        const {target} = $event
        let value = null;
        if(this.props.customHandlers && this.props.customHandlers[target.name]) {
            value = this.props.customHandlers[target.name]($event, this.state, this.setField)
        } else {
            value = target.type === 'checkbox' ? target.checked : target.value
        }
        this.setState({[target.name]: value})
        setTimeout(this.triggerOnFormChange, 0)
    }

    getValue(name, defaultValue = null) {
        return this.state ? this.state[name] : defaultValue
    }

    triggerOnFormChange() {
        const { onChange } = this.props
        if (onChange) {
            onChange(this.state)
        }
    }

    onSubmit($event) {
        $event.stopPropagation()
        $event.preventDefault()
        const { onSubmit } = this.props
        if(onSubmit) {
            if (this.validate()) {
                const {...fields} = this.state
                onSubmit($event, fields)
            }
        }
    }

    validate() {
        const results = Object.values(this.validationListeners).map(listener => listener())
        for(let i = 0; i < results.length; i++) {
            if(!results[i])
                return false
        }
        return true
    }

    addValidationListener(name, listener) {
        this.validationListeners[name] = listener
    }

    removeValidationListener(name) {
        delete this.validationListeners[name]
    }

    render() {
        const {children} = this.props
        return (
            <FormContext.Provider value={{
                setField: this.setField,
                handleChange: this.handleChange,
                getValue: this.getValue,
                addValidationListener: this.addValidationListener,
                removeValidationListener: this.removeValidationListener,
                validate: this.validate
            }}>
                <form onSubmit={this.onSubmit} noValidate>
                    {children}
                </form>
            </FormContext.Provider>
        )
    }
}

export const withFormController = (Comp) => ({...props}) => {
    return (
        <FormContext.Consumer>
            {formController => <Comp formController={formController} {...props} />}
        </FormContext.Consumer>
    )
}