import React, { Component, Fragment } from 'react';
import { withPageContent } from '../pageProvider';
import { withNavigation } from '../navigation';
import { SectionResolver } from '../section';
import Footer from '../footer/Footer';
import { Cart } from '../cart';
import { GdprDialog } from '../gdpr';

class Page extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { sections } = this.props;
        return (
           
            <Fragment>
                {sections && sections.map((section, key) => (
                        <SectionResolver key={key} {...section.sys} {...section.fields}></SectionResolver> 
                ))}
                
                <Footer />
                <Cart />
                <GdprDialog />
            </Fragment>
        );
    }
}

export default withNavigation(withPageContent(Page));