import React, { createContext, Component } from 'react';
import axios from 'axios';
import { setCookie, getCookie } from '../../utils/cookies';

const CartContext = createContext()

export default class CartProvider extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cart: {},
            checkout: {
                paymentMethod: '',
                shipping: {}
            }
        }
        this.shippingFetched = false;
        this.billingFetched = false;
        this.emptyCart = this.emptyCart.bind(this)
        this.addItem = this.addItem.bind(this)
        this.removeItem = this.removeItem.bind(this)
        this.getCart = this.getCart.bind(this)
        this.getLineItem = this.getLineItem.bind(this)
        this.setShipping = this.setShipping.bind(this)
        this.getCheckout = this.getCheckout.bind(this)
        this.completeCheckout = this.completeCheckout.bind(this)
        this.getCount = this.getCount.bind(this)
        this.attachAnimationTrigger = this.attachAnimationTrigger.bind(this)
        this.setQuantity = this.setQuantity.bind(this)
        this.getSubTotal = this.getSubTotal.bind(this)
        this.getTotal = this.getTotal.bind(this)
        this.validateShipping = this.validateShipping.bind(this)
        this.setPaymentMethod = this.setPaymentMethod.bind(this)
        this.initCart()
    }

    initCart() {
        const stored = getCookie('cart')
        if(stored) {
            setTimeout(() => this.setState(stored.val), 0)
        }
    }

    emptyCart() {
        this.persist({ cart: {} })
    }

    addItem(item, qty) {
        const { cart } = this.state
        const keys = Object.keys(cart)
        let shouldCreate = true
        for(let i = 0; i < keys.length; i++) {
            const key = keys[i]
            if(cart[key].id === item.id) {
                cart[key].qty += qty
                shouldCreate = false
                break
            }
        }
        if (shouldCreate) {
            cart[this.randomId()] = this.getLineItem(item, qty)
        }
        this.persist({ cart })
        this.triggerAnimation()
    }

    removeItem(productId) {
        const { cart } = this.state
        let id
        const values = Object.values(cart)
        for(let i = 0; i < values.length; i++) {
            if (values[i].id === productId) {
                id = Object.keys(cart)[i]
                break
            }  
        }
        delete cart[id]

        this.persist({ cart })

        return Object.keys(cart).length
    }

    getCart() {
        return this.state.cart
    }

    getCount() {
        const { cart } = this.state
        const keys = Object.keys(cart)
        return keys.reduce((val, current) => {
            return val + cart[current].qty
        }, 0)
    }

    getSubTotal() {
        const { cart } = this.state
        const keys = Object.keys(cart)
        return keys.reduce((val, current) => {
            return val + (cart[current].price * cart[current].qty)
        }, 0)
    }

    getLineItem(item, qty) {
        delete item.description
        item.images = [item.images[0]]
        item.qty = qty
        return item
    }

    setQuantity(id, qty) {
        const { cart } = this.state
        cart[id].qty = qty
        this.persist({ cart })
    }

    attachAnimationTrigger(fn) {
        this.onAnimationTrigger = fn;
    }

    triggerAnimation() {
        if(this.onAnimationTrigger) {
            this.onAnimationTrigger()
        }
    }

    setShipping(shipping) {
        const { checkout } = this.state
        checkout.shipping = shipping
        this.persist({ checkout })
    }

    validateShipping() {
        const { checkout: { shipping } } = this.state
        if(!shipping) {
            return { inputs: true }
        }
        const { firstName, lastName, email, address1, city, postalCode, countryCode, shippingMethod } = shipping
        const errors = {}
        if (!shippingMethod) {
            errors.shippingMethod = true
        }
        if (!firstName || !lastName || !email || !address1 || !city || !postalCode || !countryCode) {
            errors.inputs = true
        }
        return Object.keys(errors).length !== 0 ? errors : null
    }

    setPaymentMethod(form) {
        const { checkout } = this.state
        checkout.paymentMethod = form.paymentMethod
        this.persist({ checkout })
    }

    persist(obj){
        this.setState(obj)
        // to ensure state persistence is complete
        setTimeout(() => {
            setCookie('cart', this.state, 2)
        }, 0)
    }

    getCheckout() {
        return this.state.checkout
    }

    completeCheckout() {
        // const { checkout, cart } = this.state
        return axios(
            {
                method: 'post',
                url: `/api/checkout/`,
                headers: {
                    'Content-Type' : 'application/json'
                },
                data: {
                    ...this.state
                }
            }
        )
    }

    getTotal(){
        let total = this.getSubTotal()
        total += this.state.checkout.shipping.price
        return total
    }

    render() {
        const { children } = this.props
        return (
            <CartContext.Provider value={{
                
                cart: {
                    empty: this.emptyCart,
                    addItem: this.addItem,
                    removeItem: this.removeItem,
                    get: this.getCart,
                    getCount: this.getCount,
                    attachAnimationTrigger: this.attachAnimationTrigger,
                    setQuantity: this.setQuantity,
                    getTotal: this.getSubTotal
                },
                checkout: {
                    setShipping: this.setShipping,
                    validateShipping: this.validateShipping,
                    get: this.getCheckout,
                    complete: this.completeCheckout,
                    getTotal: this.getTotal,
                    setPaymentMethod: this.setPaymentMethod
                }
            }}>
                {children}
            </CartContext.Provider>
        )
    }

    randomId() {
        const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
        const len = (Math.random() * 3) + 12
        let output = ''
        for(let i = 0; i < len; i++) {
            const index = Math.floor(Math.random() * chars.length)
            output += chars.charAt(index)
        } 
        return output
    }
}

export const withCart = Comp => props => (
    <CartContext.Consumer>
        {value => <Comp {...value} {...props} />}
    </CartContext.Consumer>
)
