import React, {Component} from 'react'
import { DefaultInput, InputContainer, InputArea, FlagContainer, PhoneLabel, TelInput } from './styles'
import { withFormController } from '../Form';
import { Label } from '../styles';
import { AsYouType } from 'libphonenumber-js';
import { requiredValidation } from '../utils';
import { withNotificationManager } from '../../notifications';

let index = 0;

class Input extends Component {
    constructor(props) {
        super(props)
        this.name = props.name || `input${index++}`
        this.state = {
            focused: false
        }
        this.type = props.type || 'text'
        this.onBlur = this.onBlur.bind(this)
        this.onFocus = this.onFocus.bind(this)
        this.onTelChange = this.onTelChange.bind(this)
        this.onClick = this.onClick.bind(this)
        this.validate = this.validate.bind(this)
    }

    onFocus() {
        this.setState({focused: true})
    }

    onBlur() {
        this.setState({focused: false})
    }

    onTelChange($event) {
        const phoneNum = new AsYouType();
        phoneNum.input($event.target.value)
        this.props.formController.handleChange($event)
    }

    onClick() {
        this.input.focus();
    }

    validate() {
        if(!requiredValidation(this.props, this.name)) {
            const { notificationManager, placeholder } = this.props
            notificationManager.add('warning', `'${placeholder}' required`, `You need to provide a value before continuing.`, true, 10000)
            return false
        }
        return true
    }
    
    componentDidMount() {
        this.props.formController.addValidationListener(this.name, this.validate)
    }

    componentWillUnmount() {
        this.props.formController.removeValidationListener(this.name)
    }

    render() {
        const {formController: {getValue, handleChange}, placeholder, required, ...props} = this.props
        props.required = required
        const value = getValue(this.name) || ''
        const { focused } = this.state
        switch (this.type) {
                case 'tel':
                    const { countryCode = getValue('countryCode') || 'AF'} = props
                    return (
                        <InputContainer>
                            <InputArea onClick={this.onClick} focused={focused}>
                            <FlagContainer>
                                <img src={`https://www.countryflags.io/${countryCode}/flat/16.png`} />
                            </FlagContainer>
                            <PhoneLabel htmlFor={this.name} hasValue={value !== ''} focused={focused}> {placeholder} {required && <span className="required">*</span>}</PhoneLabel>
                            <TelInput ref={(input) => this.input = input} id={this.name} name={this.name} onFocus={this.onFocus} onBlur={this.onBlur} onChange={this.onTelChange} value={value} {...props} />
                            </InputArea>
                        </InputContainer>
                    )
                case 'text':            
                default:
                    return (
                        <InputContainer>
                            <Label htmlFor={this.name} hasValue={value !== ''} focused={focused}>{placeholder} {required && <span className="required">*</span>}</Label>
                            <DefaultInput id={this.name} name={this.name} onFocus={this.onFocus} onBlur={this.onBlur} onChange={handleChange} value={value} {...props} />
                        </InputContainer>
                    )
        }
            
    }
}

export default withNotificationManager(withFormController(Input))