import styled from 'styled-components';

export const FaqContainer = styled.div`
    
`

export const FaqItem = styled.div`
    position: relative;
    
    h1, h2 {
        display: none;
    }
    h3 {
        cursor: pointer;
        border: 1px solid #F4E2F3;
        margin: unset;
        padding: 1em 0.5em;
    }
    h4 {
        height: ${({open}) => open ? 'auto' : '0'};
        opacity: ${({open}) => open ? '1' : '0'};
        
        transition: height 0.5s, opacity 0.5s, padding 0.5s;
        overflow: hidden;
        margin: unset;
        padding: 1em 0.5em 1em 3em;
        padding-top: ${({open}) => open ? '1em' : '0'};
        padding-bottom: ${({open}) => open ? '1em' : '0'};
    }
`

export const ArrowContainer = styled.div`
    position: absolute;
    top: 1.5em;
    right: 15px;
`

export const Arrow = styled.div`
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transition: transform 0.5s;
    ${
        ({orientation}) => {
            switch(orientation) {
                case 'right':
                    return `
                        transform: rotate(-45deg);
                        -webkit-transform: rotate(-45deg);
                    `
                case 'down': 
                    return `
                        transform: rotate(45deg);
                        -webkit-transform: rotate(45deg);
                    `
            }
        }
    }
`