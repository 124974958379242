import React, { Component, Fragment } from 'react'
import { ObserverSpan } from './styles'

export default class IntersectObserver extends Component {

    

    constructor(props) {
        super(props)
        this.container = React.createRef()
        this.state = {
            inView: false
        }
    }

    componentDidMount() {
        const options = this.props.options ? this.props.options : {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
        }
        this.observer = new IntersectionObserver((changes, observer) => {
            this.observer = observer
            if (changes[0].intersectionRatio > 0) {
                this.setState({inView: true})
            } else {
                this.setState({inView: false})
            }
            
        }, options)
        this.observer.observe(this.container.current)
    }


    render() {
        const { children } = this.props
        const { inView } = this.state
        return (
            <ObserverSpan ref={this.container}>
                {children(inView, this.observer, this.container.current)}
            </ObserverSpan>
        )
    }
}