import React from 'react'
import { MutableHeading } from './heading'
import { StyledMarkdown } from './styles'
import { CtaLink } from './link'

export default (props) => {
    const { children, nodes } = props
    const renderers = { ...nodes, heading: MutableHeading, link: CtaLink }
    return (
        <StyledMarkdown source={children} renderers={renderers} />
    );
}