import { PageHead } from './PageHead'
import { Generic } from './Generic'
import { Cards } from './Cards'
import { SimpleText } from './SimpleText'
import { Testimonies } from './Testimonies'
import { Faq } from './Faq'
import { Product } from './Product'
import { ThankYou } from './ThankYou'
import { Media } from './Media'

export default {
    PageHead,
    Generic,
    Cards,
    SimpleText,
    Testimonies,
    Faq,
    Product,
    ThankYou,
    Media
};