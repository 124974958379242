import React from 'react'

const divider = ({color = '#1d181d'}) => (
<svg
   id="svg8"
   version="1.1"
   viewBox="0 0 26.458333 2.6458334"
   height="10"
   className="divider"
   width="100">
  <defs
     id="defs2" />  
  <g id="layer1">
    <path
       id="path833"
       d="M 5.2916919,1.3244986 21.166632,1.3229828"
       style={{
           fill: 'none',
           stroke: color,
           strokeWidth: '0.529299'
       }}
       />
    <circle
       r="0.52916664"
       cy="1.332399"
       cx="21.341478"
       id="path835"
       style={{
            fill: color,
            stroke: 'none',
            strokeWidth: '1.02786',
            fillOpacity: 1
        }}
     />
    <circle
        style={{
            fill: color,
            stroke: 'none',
            strokeWidth: '1.02786',
            fillOpacity: 1
        }}
        id="path835-4"
        cx="5.1971726"
        cy="1.2992932"
        r="0.52916664" />
    <circle
        r="0.52916664"
        cy="1.3229166"
        cx="11.1125"
        id="path835-4-6"
        style={{
                fill: color,
                stroke: 'none',
                strokeWidth: '1.02786',
                fillOpacity: 1
        }} />
    <ellipse
       ry="0.79374999"
       rx="0.52916664"
       style={{
            fill: color,
            stroke: 'none',
            strokeWidth: '1.25887',
            fillOpacity: 1
        }}
        id="path835-4-6-9"
        cx="12.170834"
        cy="1.3276414" />
    <ellipse
        cy="1.3229166"
        cx="13.229167"
        id="path835-4-6-9-1"
        style={{
            fill: color,
            stroke: 'none',
            strokeWidth: '1.455362',
            fillOpacity: 1
        }}
        rx="0.52916664"
        ry="1.0583333" />
    <ellipse
        cy="1.3229166"
        cx="14.2875"
        id="path835-4-6-9-3"
        style={{
            fill: color,
            stroke: 'none',
            strokeWidth: '1.25887',
            fillOpacity: 1
        }}
        rx="0.52916664"
        ry="0.79374999" />
    <circle
        style={{
            fill: color,
            stroke: 'none',
            strokeWidth: '1.02786',
            fillOpacity: 1
        }}
        id="path835-4-6-2"
        cx="15.345835"
        cy="1.3229166"
        r="0.52916664" />
  </g>
</svg>
)


export default divider