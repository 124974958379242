import React, { Component } from 'react';
import { Container, CardsContainer, Card, CardContainer } from './styles';
import { Text } from '../../text';
import { SimpleLazyImage } from '../../lazyImage';
import { Appear } from '../../appear';

export default class Cards extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { items, heading } = this.props;
        return (
            <Container>
                
                {heading && (
                    <Appear>
                        <Text>{heading.fields.text}</Text>
                    </Appear>
                )}
                
                <CardsContainer>
                    { items && items.map((card, index) => {
                        const { image, text, name, backgroundColor, customCss } = card.fields
                        let content = 'not here'
                        if ( text ) {
                            content = text.fields.text
                        }
                        
                        return (
                            <CardContainer>
                                <Appear delay={250 * index}>
                                    <Card
                                        key={`card_${name}_${index}`} 
                                        color={backgroundColor ? backgroundColor.fields.color : null}
                                        customCss={customCss}>
                                        {image && image.length > 0 && <SimpleLazyImage image={image} />}
                                        <Text>{content}</Text>
                                    </Card>
                                </Appear>
                            </CardContainer>
                        )
                    })}
                </CardsContainer>
            </Container>
        );
    }
}