import React, { createContext, Component } from 'react'
import { get } from 'axios'


const GlobalResourceContext = createContext()

export default class GlobalResourceProvider extends Component {

    state = {
        navTree: [],
        footerTree: {}
    }

    cached = false

    constructor(props) {
        super(props)
        this.fetchNavTree()
    }

    async fetchNavTree() {
        if(!this.cached) {
            try {
                const nav = await get('/api/navigation/')
                const foot = await get('/api/footer/')
                this.cached = true
                this.setState({navTree: nav.data, footerTree: foot.data})
            } catch (e) {
                console.error(`Could not fetch GlobalResource: ${e}`)
            }
        }
    }


    render() {
        const { navTree, footerTree } = this.state
        const { children } = this.props
        return (
            <GlobalResourceContext.Provider value={{
                navTree, 
                footerTree
            }}>
                {children}
            </GlobalResourceContext.Provider>
        )
    }
}

export const withGlobalResources = (Component) => (props) => (
    <GlobalResourceContext.Consumer>
        {value => <Component {...value} {...props} />}
    </GlobalResourceContext.Consumer>
)
    
