import React, { createContext, Component } from 'react'
import axios from 'axios'
import { useLocation, withRouter } from 'react-router-dom'
import { LoadingContainer, LoadingText } from './styles'
import quotes from './loadingQuotes'
import ReactGA from 'react-ga'

const PageContext = createContext();

const withLocation = (Component) => (props) => {
    const location = useLocation()
    return (
        <Component location={location} {...props} />
    );
}


class LoadingIndicator extends Component{
    constructor(props) {
        super(props)
        this.state = {
            message: this.getMessage()
        }
        this.isActive = false
    }

    componentWillReceiveProps({loading}) {
        if(loading && !this.isActive) {
            
            this.setState({message: this.getMessage()})
            this.isActive = true
        } else if (!loading) {
            this.isActive = false
        }
    }

    getMessage(){
        return quotes[Math.floor(Math.random() * quotes.length)]
    }


    render() {
        const {loading} = this.props
        const {message} = this.state
        return (
            <LoadingContainer loading={loading}>
                <LoadingText loading={loading}>
                    {message}
                </LoadingText>
            </LoadingContainer>
        )
    }
}

class PageProvider extends Component {

    state = {
        loaded: false,
        sections: [],
        page: {}
    }

    constructor(props) {
        super(props);    
    }

    fetchPage(route) {
        this.setState({
            loaded: false
        })
        setTimeout(async () => {
            this.setState({sections: []})
            try {
                const path = route || this.props.location.pathname
                const result = await axios.get('/api/page/?path=' + encodeURI(path))
                const page = result.data
                if (page) {
                    this.setState({sections: result.data.fields.sections, page: result.data})
                    document.title = `Zappkit - something`
                }
                this.setState({loaded: true})
            } catch (e) {
                console.error(`Could not fetch page: ${e}`)
                this.setState({loaded: true})
            }
        }, 250)
        
    }

    componentDidMount() {
        this.fetchPage()
        this.listener = this.props.history.listen((location) => {
            this.fetchPage(location.pathname)
            ReactGA.pageview(location.pathname)
        })
    }

    componentWillUnmount() {
        this.listener()
    }

    render () {
        const { sections, page, loaded } = this.state
        const { children } = this.props;
        return (
            <PageContext.Provider value={{
                page,
                sections
            }}>
                <LoadingIndicator loading={!loaded} />
                {children}
            </PageContext.Provider>
        );
    }
}


export const withPageContent = Comp => (props) => (
    <PageContext.Consumer>
        {value => <Comp {...value} {...props} />}
    </PageContext.Consumer>
);

export default withRouter(withLocation(PageProvider));