import React, { Component, createContext } from 'react'
import Modal from './Modal'

const ModalContext = createContext()

export default class ModalProvider extends Component{

    constructor(props) {
        super(props)
        this.state = {
            open: false,
            to: ''
        }
        this.triggerClose = this.triggerClose.bind(this)
        this.triggerOpen = this.triggerOpen.bind(this)
    }

    triggerOpen(to) {
        this.setState({
            open: true,
            to
        })
    }

    triggerClose() {
        this.setState({ open: false })
        setTimeout(() => this.setState({ to: ''}), 250)
    }

    render() {
        const {open, to} = this.state
        const {children} = this.props
        return (
            <ModalContext.Provider value={{
                open: this.triggerOpen,
                close: this.triggerClose
            }}>
                {children}
                <Modal open={open} to={to} triggerClose={this.triggerClose} />
            </ModalContext.Provider>
        )
    }
}

export const withModalController = Comp => props => (
    <ModalContext.Consumer>
        {value => <Comp modalController={value} {...props} />}
    </ModalContext.Consumer>
)

const Link = props => (
        <a href="javascript:void(0);" onClick={() => props.modalController.open(props.to)}>
            {props.children}
        </a>
    )

export const ModalLink = withModalController(Link)