import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

if (!('IntersectionObserver' in window)) {
    require('babel-polyfill');
    require('react-app-polyfill/ie11');
    require('./polyfill/intersectionobserver');
}
// import { polyfill as promisePolyfill} from 'es6-promise'
// import { polyfill as oaPolyfill } from 'es6-object-assign'

// promisePolyfill()
// oaPolyfill()

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
