import styled from 'styled-components'
import { Label } from '../styles'

export const Container = styled.div`
    position: relative;
    margin: 30px 0 5px 0;
`

export const DefaultInput = styled.div`
    position: relative;
    padding: 2em;
    border: 1px solid #EEF;
    margin: 1vh 0;
    cursor: pointer;
    padding-left: 3em;
    background: ${ ({selected}) => selected ? '#EEF' : 'transparent' };
    transition: background 0.5s;
`


export const CheckCircle = styled.div`
    position: absolute;
    left: 15px;
    top: 50%;
    width: 20px;
    height: 20px;
    transform: translateY(-50%);
    border-radius: 50%;
    transition: background 0.5s;
    border: 3px solid ${({color}) => color? color : '#333'};
    background-color: ${({selected, color}) => selected ? color ? color : 'rgb(17, 64, 122)' : 'transparent'};
`

export const RadioLabel = styled(Label)`
    top: 2px;
`