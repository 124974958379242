import styled, { keyframes, css } from 'styled-components';
import { large } from '../../../utils';

export const ProductContainer = styled.div`
    display: flex;
    ul {
        list-style-type: square;
    }
    ${
        large(`
            flex-direction: column;
        `)
    }
`

export const ActionContainer = styled.div`
    flex: 0 1 auto;
`

export const AddButton = styled.div`
    display: flex;

    .qty, button {
        font-size: 17px;
    }

    button {
        height: 3em;
        border: none;
        cursor: pointer;
    }

    .qty {
        height: 3em;
        width: 3em;
        box-sizing: border-box;
        text-align: center;
        border-top: 1px solid #DDDDDD;
        border-bottom: 1px solid #DDDDDD;
        border-right: none;
        border-left: none;
    }
    
    .subtract, .add {
        width: 3em;
        background: #FFF;
        border: 1px solid #DDDDDD;
    }

    .subtract {
        border-right: none;
    }

    .add {
        border-left: none;
    }

    .subtract, .add, .qty {
        background: #EEF;
        flex: 0 1 auto;
    }

    .toCart {
        background-color: #554654;
        color: #FFF;
        flex: 1 0 auto;
        font-weight: bold;
        transition: background-color 0.5s;
        overflow: hidden;
        &:hover{
            background-color: #715d70;
        }
    }
`



const cartAnimation = keyframes`
    0% { transform: translateX(-50%); }
    25% { transform: translateX(0); }
    50% { transform: translateX(0); }
    70% { transform: translateX(50%); }
    71% { transform: translateY(400%); }
    72% { transform: translateX(-100%);}
    73% { transform: translateY(0); transform: translateX(-100%); }
    85% { transform: translateX(-50); }
    100% { transform: translateX(-50%); }
`

const itemAnimation = keyframes`
    29% { opacity: 1; top: -6px; }
    44% { top: 44%; }
    100% { top: 44%; }
`

export const ItemAdded = styled.div`
    width: 5px;
    height: 5px;
    border-radius: 2px;
    position: absolute;
    top: -6px;
    background: #FFF;
    left: 50%;
    transform: translateX(-50%);
    ${({activeAnimation}) => activeAnimation ? css`
        animation: ${itemAnimation} 3s 0s forwards;
        animation-iteration-count: infinite;
    ` : null}
`

export const CartAnimationContainer = styled.div`
    height: 100%;
    width: 201%;
    transform: translateX(-50%);
    ${({activeAnimation}) => activeAnimation ? css`
        animation: ${cartAnimation} 3s 0s forwards;
        animation-iteration-count: 1;
    ` : null}
`

export const CartIconContainer = styled.div`
    svg {
        height: 100%;
    }
    height: 100%;
    width: 50%;
    display: inline-block;
    position: relative;
`

export const CartTextContainer = styled.div`
    height: 100%;
    width: 50%;
    display: inline-block;
    vertical-align: top;
    position: relative;
    div {
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
`

   

export const Header = styled.div`
    padding: 1.5em;
    border: 1px solid #DDD;
    border-bottom: none;
`

export const Description = styled.div`
    padding: 1.5em;
    border: 1px solid #DDD;
    border-bottom: none;
    border-top: none;
`

export const ImageContainer = styled.div`
    flex: 1 0 auto;
    display: flex;
    ${
        large(`
            flex-direction: column-reverse;
        `)
    }
`

export const ThumbnailContainer = styled.div`
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    ${
        large(`
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 2vh;
        `)
    }
`

export const Thumbnail = styled.div`
    flex: 0 1 auto;
    min-width: 75px;
    max-width: 10vw;
    margin: 0 1vh 1vh 0;
    box-sizing: border-box;
    transition: box-shadow 0.5s, transform 0.5s;
    -webkit-box-shadow: 0px 3px 8px -1px rgba(85, 70, 84, 0.4);
    -moz-box-shadow: 0px 3px 8px -1px rgba(85, 70, 84, 0.4);
    box-shadow: 0px 3px 8px -1px rgba(85, 70, 84, 0.4);
    &:hover{
        -webkit-box-shadow: 0px 5px 10px -2px rgba(29, 24, 29, 0.6);
        -moz-box-shadow: 0px 5px 10px -2px rgba(29, 24, 29, 0.6);
        box-shadow: 0px 5px 10px -2px rgba(29, 24, 29, 0.6);
        transform: scale(1.03);
    }
    
    ${
        large(`
            flex: 1 1 32%;
            margin: 0;
        `)
    }
    ${({active}) => active ? 'border: 2px solid #554654' : null}
    cursor: pointer;
    &::before {
        content: "";
        width: 1px;
        margin-left: -1px;
        float: left;
        height: 0;
        padding-top: 100%;   
    }
    &::after {
        content: "";
        display: table;
        clear: both;
    }
    
    ${({src}) => src ? `
        background-image: url('${src}?w=100');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    ` : null}
`

export const ActiveImage = styled.div`
    flex: 0 1 auto;
    width: 85%;
    margin: auto;
    transition: background 0.4s;

    ${
        large(`
            width: 100%;
            margin-bottom: 2vh;
        `)
    }

    &::before {
        content: "";
        width: 1px;
        margin-left: -1px;
        float: left;
        height: 0;
        padding-top: ${({width, height}) => (width && height) ? `${(height / width) * 100}%` : '100%'};   
        transition: padding 0.5s;
    }
    &::after { /* to clear float */
        content: "";
        display: table;
        clear: both;
    }
    
    ${({src}) => src ? `
        background-image: url('${src}?w=1000');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    ` : null}
`

