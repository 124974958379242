import styled from 'styled-components';
import { small } from '../../utils';

export const AppearContainer = styled.div`
    width: 100%;
    transition: opacity 1200ms, transform 1200ms;
    opacity: ${({inView}) =>  inView ? '1' : '0'};
    transform: translateY(${({inView}) =>  inView ? '0' : '-3vh'});
    ${({delay}) => delay ? `transition-delay: ${delay}ms;` : ''}
    ${
        small(`
            transition-delay: 0ms;
        `)
    }
`