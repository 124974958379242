import React, { Component, Fragment } from 'react'
import Axios from 'axios'
import { Countries } from './Countries'
import { getCookie, setCookie } from '../../utils/cookies'
import { GdprDialogContainer, GdprButtonContainer, GdprTextContainer, GdprHeader } from './styles'

export default class GdprDialog extends Component {


    constructor(props){
        super(props)
        this.state = {
            show: false // will only change if user is inside europe and hasn't agreed
        }
        const cookie = getCookie('gdpr')
        if(!cookie) {
            Axios.get(`http://ip-api.com/json`).then(response => {
                const {countryCode} = response.data
                if( Countries[countryCode] === 'EU') {
                    this.setState({show: true})
                }
            })
        }
        this.consent = this.consent.bind(this)
    }

    consent() {
        setCookie('gdpr', true, 30)
        this.setState({show: false})
    }

    render() {
        const { show } = this.state
        return (
            <Fragment>
                {
                    show && <GdprDialogContainer><GdprHeader>Cookies &amp; Privacy</GdprHeader><GdprTextContainer>We use cookies to store your shopping cart between sessions and for analytics. By continuing to visit this website you agree to our use of cookies</GdprTextContainer><GdprButtonContainer onClick={this.consent}>Got it</GdprButtonContainer></GdprDialogContainer>
                }
            </Fragment>
        )
    }
}