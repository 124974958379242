import React, { Component } from 'react';
import { GenericContainer } from './styles';

export default class Generic extends Component {
    constructor(props) {
        super(props);
    }

    render(){
        return (
            <GenericContainer>
                
            </GenericContainer>
        );
    }
}