import React, { Component } from 'react'
import './App.css'
import PageProvider from './components/pageProvider/PageProvider'
import Page from './components/page/Page'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { CartProvider } from './components/cart'
import { GlobalResourceProvider } from './components/staticResources'
import { NotificationManagerProvider } from './components/notifications'
import ReactGA from 'react-ga'
import { ModalProvider } from './components/modal'

class App extends Component {

  constructor(props) {
    super(props)

    ReactGA.initialize('UA-50398569-1')
  }

  render() {
    return (
      <GlobalResourceProvider>
        <Router>
          <Switch>
            <Route path="/">
              <PageProvider>
                <NotificationManagerProvider>
                  <ModalProvider>
                    <CartProvider>
                      <Page />
                    </CartProvider>
                  </ModalProvider>
                </NotificationManagerProvider>
              </PageProvider>
            </Route>
          </Switch>
        </Router>
      </GlobalResourceProvider>
    )
  }
}

export default App
