import React, { Component } from 'react'
import { IntersectionObserver } from '../intersectionObserver'

export default class LazyLoading extends Component {

    

    constructor(props) {
        super(props)
        this.setState({output: props.placeholder})
        this.state = {
            output: props.placeholder || '',
            loaded: false
        }
        this.observerHandler = this.observerHandler.bind(this)
    }

    observerHandler(inView, observer, target) {
        let { output, loaded } = this.state
        if (inView && !loaded) {
            const { action } = this.props
            setTimeout(() => {
                action().then(result => {
                    this.setState({output: result, loaded: true})
                    observer.unobserve(target)
                })
            }, 0)
        }
        return this.props.children(output)
    }

    render() {
        return (
            <IntersectionObserver>
                {this.observerHandler}
            </IntersectionObserver>
        )
    }
}