import React, { Component } from "react";
import { IntersectionObserver } from "../intersectionObserver";
import { AppearContainer } from './styles';

export default class Appear extends Component {
    render () {
        const { children, delay, renew } = this.props
        return (
            <IntersectionObserver>
                {(inView, observer, target) => { 
                    if (inView) {
                        observer.unobserve(target)
                    }
                    return (
                        <AppearContainer inView={inView} delay={delay}>
                            {children}
                        </AppearContainer>
                    )}}
            </IntersectionObserver>
        )
    }
}

