import React, { Component, Fragment } from 'react';
import SectionTypes from '../sectionTypes/';


export default class SectionResolver extends Component {


    constructor(props) {
        super(props);
        this.section = SectionTypes[props.type];
    }

    render() {
        const Comp = this.section ? this.section : Fragment;
        return (
            <Comp {...this.props} />
        );
    }
}