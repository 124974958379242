import React, {createContext, Component} from 'react'
import { Info, Error, Warning, Success, Container, IconContainer, TextContainer, Header, Message, CloseContainer, IconCircle } from './styles'
import { Close, Checkmark, ErrorIcon, WarningIcon, InfoIcon } from './icons'

const NotificationContext = createContext()

export default class NotificationManagerProvider extends Component {

    constructor(props) {
        super(props)
        this.state = {
            notifications: {}
        }
        this.add = this.add.bind(this)
        this.getNotifications = this.getNotifications.bind(this)
        this.removeNotification = this.removeNotification.bind(this)
    }

    add(type = 'info', header = '', message = '', closeable = true, duration = -1) {
        const { notifications } = this.state
        const guid = createGuid()

        notifications[guid] = {
            type,
            header,
            message,
            closeable,
            duration,
            recentlyAdded: true
        }
        setTimeout(() => {
            notifications[guid].recentlyAdded = false
            this.setState({notifications})
        }, 50);
        if(duration > 0) {
            // add a removal countdown
            setTimeout(() => {
                this.removeNotification(guid)
            }, duration)
        }
        this.setState({notifications})
    }

    removeNotification(id) {
        const { notifications } = this.state
        notifications[id].closing = true
        this.setState({notifications})
        setTimeout(() => {
            delete notifications[id]
            this.setState({notifications})
        }, 550)
    }

    getNotifications() {
        const { notifications } = this.props
        if(!notifications) {
            return []
        }
        const keys = Object.keys(notifications)
        return keys.map(key => notifications[key])
    }

    render() {
        const { children } = this.props
        const { notifications } = this.state
        const keys = Object.keys(notifications)
        return (
            <NotificationContext.Provider value={{
                add: this.add
            }}>
                {children}
                <Container>
                {keys && keys.map((id, i) => {
                    const n = notifications[id]
                    let Notification
                    let Icon = Checkmark
                    switch(n.type) {
                        case 'error':
                            Notification = Error
                            Icon = ErrorIcon
                            break
                        case 'warning':
                            Notification = Warning
                            Icon = WarningIcon
                            break
                        case 'success':
                            Notification = Success
                            Icon = Checkmark
                            break
                        case 'info':
                        default:
                            Notification = Info
                            Icon = InfoIcon
                    }
                    const classes = `${n.closing ? 'closing' : null} ${n.recentlyAdded ? 'opening' : null}`
                    return (
                        <Notification className={classes} key={`notification${i}`}>
                            <IconContainer>
                                <IconCircle>
                                    <Icon height="20" width="20" color="#FFF" />
                                </IconCircle>
                            </IconContainer>
                            <TextContainer>
                                <Header>{n.header}</Header>
                                <Message>{n.message}</Message>
                            </TextContainer>
                            {n.closeable && (
                                <CloseContainer>
                                    <Close color="#AAA" height="15" width="15" onClick={() => this.removeNotification(id)} />
                                </CloseContainer>
                            )}
                            
                        </Notification>
                    )
                })}
                </Container>
            </NotificationContext.Provider>
        )
    }
}

export const withNotificationManager = (Comp) => (props) => (
    <NotificationContext.Consumer>
        {nm => <Comp notificationManager={nm} {...props} />}
    </NotificationContext.Consumer>
)

const createGuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        // eslint-disable-next-line
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
}