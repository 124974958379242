import React, {Component} from 'react'
import { Container, StyledSelect } from './styles'
import { Label } from '../styles'
import { withFormController } from '../Form'
import { requiredValidation } from '../utils'
import { withNotificationManager } from '../../notifications'

let index = 0

export class Select extends Component {
    constructor(props) {
        super(props)
        this.index = index++
        this.name = props.name || `select${this.index}`
        this.validate = this.validate.bind(this)
    }

    validate() {
        if(!requiredValidation(this.props, this.name)) {
            const { notificationManager, placeholder } = this.props
            notificationManager.add('warning', `'${placeholder}' required`, `You need to provide a value before continuing.`, true, 10000)
            return false
        }
        return true
    }
    
    componentDidMount() {
        this.props.formController.addValidationListener(this.name, this.validate)
    }

    componentWillUnmount() {
        this.props.formController.removeValidationListener(this.name)
    }

    render() {
        const { formController: {getValue, handleChange}, options, value = 'value', label = 'label', placeholder, required } = this.props
        const val = getValue(this.name, options[0][value])
        return (
            <Container>
                <Label htmlFor={this.name} hasValue={val !== ''} focused="true">{placeholder} {required && <span className="required">*</span>}</Label>
                <StyledSelect name={this.name} value={val} onChange={handleChange}>
                    {options && options.map((o, i) => <option key={`option${this.index}-${i}`} value={o[value]}>{o[label]}</option>)}
                </StyledSelect>
            </Container>
        )
    }
}

export default withNotificationManager(withFormController(Select))