import React from 'react'
import styled from 'styled-components'
import { small } from './styled-component-helpers'

export const ChevronButton = (props) => (
    <ChevronContainer {...props}>
        <div></div>
        <div></div>
    </ChevronContainer>
)

const ChevronContainer = styled.a`
    position: relative;
    height: 50px;
    width: 50px;
    display: block;
    ${({onClick}) => onClick ? 'cursor: pointer' : null}
    div{
        position: absolute;
        height: 5px;
        width: 50%;
        background: #0a346a;
        transition: all 200ms ease-in-out;
        &:nth-child(1) {
            top: 13px;
            transform: rotate(${({orientation}) => orientation && orientation === 'left' ? '-' : ''}55deg)
        }
        &:nth-child(2) {
            bottom: 13px;
            transform: rotate(${({orientation}) => orientation && orientation === 'left' ? '' : '-'}55deg)
        }
    }
`

export const Small = styled.div`
    display: none;
    ${small(`
        display: block !important;
    `)}
`