import styled from 'styled-components';
import { GenericContainer } from '../Generic/styles';
import { small, medium } from '../../../utils';

export const Container = styled(GenericContainer)`

`

export const TestimoniesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
`

export const TestimonyContainer = styled.div`
    flex: 1 0 auto;
    min-width: 29%;
    max-width: 30%;
    margin: 1% 0;
    ${
        medium(`
            max-width: 45%;
            width: 45%;
        `)
    }
    ${
        small(`
            max-width: 100%;
            width: 100%;
            margin-bottom: 4vh;
        `)
    }
`

export const Testimony = styled.div`
    position: relative;
    width: 100%;
    min-height: 300px;
    border: 1px solid #fcfafc;
    -webkit-box-shadow: 0px 10px 20px -5px rgba(29, 24, 29, 0.25);
    -moz-box-shadow: 0px 10px 20px -5px rgba(29, 24, 29, 0.25);
    box-shadow: 0px 10px 20px -5px rgba(29, 24, 29, 0.25);
    box-sizing: border-box;
    padding: 1.5vw;
    transition: all 0.5s;

    ${ 
        small(`
            padding: 4vw;
        `)
    }
    img {
        display: block;
        border-radius: 50%;
        margin: auto;
        width: 28%;
        -webkit-box-shadow: 0px 6px 16px -3px rgba(85, 70, 84, 0.6);
        -moz-box-shadow: 0px 6px 16px -3px rgba(85, 70, 84, 0.6);
        box-shadow: 0px 6px 16px -3px rgba(85, 70, 84, 0.6);
    }

    &:hover{
        -webkit-box-shadow: 0px 10px 20px -5px rgba(29, 24, 29, 0.5);
        -moz-box-shadow: 0px 10px 20px -5px rgba(29, 24, 29, 0.5);
        box-shadow: 0px 10px 20px -5px rgba(29, 24, 29, 0.5);
        transform: scale(1.03);
    },
    h1, h2, h3, h4, h5 {
        text-align: center;
    }
    p {
        text-align: left;
    }
    .divider {
        margin: 15px auto 2px auto;
        height: 15px;
        width: 150px;
    }
`