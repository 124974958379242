import styled from 'styled-components';

export const GenericContainer = styled.div`
    position: relative;
    width: 100%;
    background: ${({color, image}) => image ? `url(${image})` : color ? color.fields.color : '#FFF'};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: inherit;
    padding: 3% 7%;
    box-sizing: border-box;
    text-align: center;
`